import { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import logo from '../../assets/logo-getnet-full@2x.png';
import Input from '../../components/Input/Input';
import { verifyEmailToken, activateUser } from '../../services/user.service';
import schemas from '../../utils/schemas';
import resultMessage from '../../utils/resultMessage';

interface ActivateAccountValues {
  document: number | null;
  password: string;
  confirmPassword: string;
}

function ActivateAccount() {
  let emailTokenParams = useParams();
  const token = emailTokenParams.token;
  const [isActive, setIsActive] = useState<boolean>(false);
  const [error, setError] = useState<string>('')
  const navigate = useNavigate();

  const submitActivation = async (values: ActivateAccountValues) => {
    try {
      const activeUserResponse = await activateUser(
        String(token),
        Number(values.document),
        values.password,
        values.confirmPassword,
      );
      setError('')
      navigate('/resultado', { state: { activeUserResponse: activeUserResponse.result } });
    } catch(err: any) {
      setError(err.response.data.message!)
    }
  };

  const fetchVerifyEmailToken = useCallback(async (setIsActive, verifyEmailToken, token) => {
    const active = await verifyEmailToken(String(token));

    if (!active.result) {
      return navigate('/');
    }
    setIsActive(active.data.isActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchVerifyEmailToken(setIsActive, verifyEmailToken, token);
  }, [fetchVerifyEmailToken, token]);

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          <Card>
            <CardHeader>
              <img src={logo} alt="Getnet" style={{ width: 152 }} />
              <h1>Activación de cuenta</h1>
            </CardHeader>
            <Card.Body>
              <p>{isActive ? resultMessage.alreadyActivateAccount.text : 'Establece una contraseña'}</p>
              {isActive ? (
                <Link to="/ingresar" className="btn primary-outlined-button">
                  Ir al login
                </Link>
              ) : (
                <Formik
                  onSubmit={submitActivation}
                  initialValues={{ document: null, password: '', confirmPassword: '' }}
                  validationSchema={schemas.ActivationSchema}
                >
                  <Form>
                    <Input
                      className="position-relative pb-3"
                      type="number"
                      name="document"
                      placeholder="Documento de identidad"
                    />
                    <Input
                      className="position-relative pb-3"
                      type="password"
                      name="password"
                      placeholder="Nueva contraseña"
                    />
                    <Input
                      className="position-relative pb-3"
                      type="password"
                      name="confirmPassword"
                      placeholder="Repetir contraseña"
                    />
                    <Button type="submit" className="primary-outlined-button w-100">
                      Activar Cuenta
                    </Button>
                    {error && (<p className="activation-error">{error}</p>)}
                  </Form>
                </Formik>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ActivateAccount;
