import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Input/Select';
import {
  InfoBlockField,
  NewBeneficiaryFamiliarPepValues,
  NewBeneficiaryValues,
  NewBranchValues,
} from '../../../types/add_commerce.types';
import { DocumentTypeEnum } from '../../../types/enums/document-type.enum';
import { documentTypes } from '../../../utils/data';
import schemas from '../../../utils/schemas';

interface NewBeneficiaryProps {
  innerRef: any;
  innerRefFamiliarPep: any;
  submitNewBeneficiary(values: NewBeneficiaryValues, index: number): void;
  submitBeneficiaryFamiliarPep: (values: NewBeneficiaryFamiliarPepValues, index: number) => void;
  branches: Array<NewBranchValues>;
  index: number;
  beneficiaryData: NewBeneficiaryValues;
  handleDelete: (index: number) => void;
}

function NewBeneficiary({
  innerRef,
  innerRefFamiliarPep,
  submitNewBeneficiary,
  submitBeneficiaryFamiliarPep,
  branches,
  index,
  beneficiaryData,
  handleDelete,
}: NewBeneficiaryProps) {
  const [togglePepRelativeForm, setTogglePepRelativeForm] = useState<boolean>(
    beneficiaryData?.isRelative || false,
  );

  const handleFamiliarPepChange = (
    value: boolean,
    setFieldValue: (fieldName: string, value: boolean) => void,
  ) => {
    setFieldValue('isPep', false);
    setFieldValue('isRelative', !value);
    setTogglePepRelativeForm(!value);
  };

  const handlePepChange = (value: boolean, setFieldValue: (fieldName: string, value: boolean) => void) => {
    setFieldValue('isPep', !value);
    setFieldValue('isRelative', false);
    setTogglePepRelativeForm(false);
  };

  return (
    <>
      <Formik
        innerRef={innerRef}
        initialValues={{
          documentType: DocumentTypeEnum[beneficiaryData?.documentType as keyof typeof DocumentTypeEnum],
          documentNumber: beneficiaryData?.documentNumber || '',
          firstName: beneficiaryData?.firstName || '',
          lastName: beneficiaryData?.lastName || '',
          participationPercentage: beneficiaryData?.participationPercentage || null,
          branchOfficeId: beneficiaryData.branchOfficeId,
          isPep: beneficiaryData?.isPep || false,
          isRelative: beneficiaryData?.isRelative || false,
          beneficiary_branch_office: beneficiaryData.beneficiary_branch_office || null,
        }}
        validationSchema={schemas.ActivateCommerceThirdStep}
        onSubmit={(values: NewBeneficiaryValues) => submitNewBeneficiary(values, index)}
        enableReinitialize
        validateOnMount
      >
        {({ values, setFieldValue }) => (
          <Form className="form-row">
            <div className={clsx('title-row', index > 0 && 'with-icon')}>
              <p className="input-subtitle">BENEFICIARIO {index + 1}</p>
              {index > 0 && (
                <FontAwesomeIcon
                  className="faTrashCan"
                  icon={faTrashCan as IconProp}
                  onClick={() => handleDelete(index)}
                />
              )}
            </div>
            <Row>
              <Select
                className="col-md-6"
                name="documentType"
                label="Tipo de Documento"
                required
                index={index}
                isNumericValue
              >
                {documentTypes.map((documentType: InfoBlockField) => (
                  <option key={documentType.value} value={documentType.value}>
                    {documentType.name}
                  </option>
                ))}
              </Select>
              <Input
                className="input col-md-6"
                type={values.documentType === DocumentTypeEnum.CI ? 'number' : 'text'}
                name="documentNumber"
                label="Nº de Documento"
                required
                index={index}
              />
              <Input
                className="input col-md-6"
                type="text"
                name="firstName"
                label="Nombre"
                required
                index={index}
              />
              <Input
                className="input col-md-6"
                type="text"
                name="lastName"
                label="Apellido"
                required
                index={index}
              />
              <Input
                className="input col-md-6"
                type="text"
                name="participationPercentage"
                label="% de participación"
                required
                index={index}
              />
              <Select
                className="col-md-6"
                name="branchOfficeId"
                label="Sucursal"
                required
                index={index}
                isNumericValue
              >
                {branches?.length > 1 && (
                  <option key="todas" value={0}>
                    Todas
                  </option>
                )}
                {branches?.map((branch: NewBranchValues, mapIndex: number) => (
                  <option key={mapIndex} value={branch.id}>
                    {branch.fantasyName}
                  </option>
                ))}
              </Select>
              <label className="checkbox-label col-md-3 col-6" htmlFor={`isPEP_${index}`}>
                <Field
                  className="checkbox"
                  type="checkbox"
                  name="isPep"
                  onChange={() => handlePepChange(values.isPep, setFieldValue)}
                  id={`isPEP_${index}`}
                />
                Es PEP
              </label>
              <label className="checkbox-label col-md-3 col-6" htmlFor={`isRelative_${index}`}>
                <Field
                  className="checkbox"
                  type="checkbox"
                  name="isRelative"
                  onChange={() => handleFamiliarPepChange(values.isRelative, setFieldValue)}
                  id={`isRelative_${index}`}
                />
                Es Familiar
              </label>
            </Row>
          </Form>
        )}
      </Formik>
      {togglePepRelativeForm && (
        <Formik
          innerRef={innerRefFamiliarPep}
          initialValues={{
            relativeDocumentType: beneficiaryData?.relativeDocumentType
              ? DocumentTypeEnum[beneficiaryData?.relativeDocumentType as keyof typeof DocumentTypeEnum]
              : '',
            relativeDocumentNumber: beneficiaryData?.relativeDocumentNumber || '',
            relativeFirstName: beneficiaryData?.relativeFirstName! || '',
            relativeLastName: beneficiaryData?.relativeLastName! || '',
          }}
          validationSchema={schemas.ActivateCommerceThirdStepFamiliarPep}
          onSubmit={(values: NewBeneficiaryFamiliarPepValues) => submitBeneficiaryFamiliarPep(values, index)}
          enableReinitialize
        >
          <Form className="form-row">
            <Row>
              <Select
                className="col-md-6"
                name="relativeDocumentType"
                label="Tipo de Documento"
                required
                index={index}
                isNumericValue
              >
                {documentTypes.map((documentType: InfoBlockField) => (
                  <option key={documentType.value} value={documentType.value}>
                    {documentType.name}
                  </option>
                ))}
              </Select>
              <Input
                className="input col-md-6"
                type="number"
                name="relativeDocumentNumber"
                label="Nº de Documento"
                required
                index={index}
              />
              <Input
                className="input col-md-6"
                type="text"
                name="relativeFirstName"
                label="Nombre"
                required
                index={index}
              />
              <Input
                className="input col-md-6"
                type="text"
                name="relativeLastName"
                label="Apellido"
                required
                index={index}
              />
            </Row>
          </Form>
        </Formik>
      )}
    </>
  );
}

export default NewBeneficiary;
