import { Dispatch, SetStateAction } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/user.service';

interface LogoutValues {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

function Logout({ setShow, show }: LogoutValues) {
  const navigate = useNavigate();
  const handleClose = () => setShow(false);

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    setShow(false);
    navigate('/ingresar');
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Card className="modal-card">
        <CardHeader>
          <h1>Cerrar sesión</h1>
        </CardHeader>
        <Card.Body>
          <p>
            ¿Estas seguro que quieres salir?
            <br /> Podrían perderse algunos datos
          </p>
          <Button className="primary-outlined-button w-100" onClick={handleLogout}>
            Cerrar sesión &gt;
          </Button>
          <Button className="primary-outlined-button w-100" onClick={handleClose}>
            Cancelar
          </Button>
        </Card.Body>
      </Card>
    </Modal>
  );
}

export default Logout;
