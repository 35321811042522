import clsx from 'clsx';
import { useField } from 'formik';
import { FocusEvent, HTMLInputTypeAttribute, ReactNode } from 'react';
import { Form } from 'react-bootstrap';

interface InputProps {
  type: HTMLInputTypeAttribute;
  name: string;
  value?: number | string;
  readOnly?: boolean;
  placeholder?: string;
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
  className?: string;
  children?: ReactNode;
  label?: string;
  index?: number;
  required?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

function Input({
  type,
  name,
  placeholder,
  readOnly,
  maxLength,
  minLength,
  max,
  min,
  className,
  children,
  label,
  index,
  required,
  disabled,
  autoComplete = 'on',
  onBlur,
}: InputProps) {
  const [field, meta, helpers] = useField(name);

  return (
    <Form.Group controlId={index ? `${name}_${index}` : name} className={className}>
      <div>
        {label && (
          <Form.Label>
            {label}
            {required && <span className="required-asterisk"> *</span>}
          </Form.Label>
        )}
        <Form.Control
          type={type}
          className={clsx(meta.error && meta.touched && 'input-error')}
          {...field}
          placeholder={placeholder}
          maxLength={maxLength}
          minLength={minLength}
          max={max}
          min={min}
          readOnly={readOnly}
          aria-describedby={index ? `${name}_${index}_error` : `${name}_error`}
          disabled={disabled}
          autoComplete={autoComplete}
          onBlur={(event: FocusEvent<HTMLInputElement, Element>) => {
            helpers.setTouched(true);
            onBlur && onBlur(event);
          }}
        />
        {children}
      </div>
      {meta.error && meta.touched && <span className="text-error form-text">{meta.error}</span>}
    </Form.Group>
  );
}

export default Input;
