import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import activeCommerceLogo from '../../../assets/icon-empresa-active.png';
import Input from '../../../components/Input/Input';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { basicInfo } from '../../../services/commerce.service';
import { CommerceInformationState, CommerceInformationValues } from '../../../types/add_commerce.types';
import { BasicInfoRequest } from '../../../types/business/request';
import { triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import schemas from '../../../utils/schemas';

function CommerceInformation() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const localState: CommerceInformationState = location.state as CommerceInformationState;
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let rutFullLength = '';

    localState?.commerceRut.toString().length < 12
      ? (rutFullLength = localState?.commerceRut.toString().padStart(12, '0'))
      : (rutFullLength = localState?.commerceRut.toString());

    setInitialValues({
      rut: rutFullLength,
      fantasyName: localState?.fantasyName || '',
      socialReason: localState?.socialReason || '',
      contactFirstName: localState?.contactFirstName || '',
      contactLastName: localState?.contactLastName || '',
      phone: localState?.phone || '',
      cellphone: localState?.cellphone || '',
      email: localState?.email || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitBasicInfo = async (values: CommerceInformationValues) => {
    setLoading(true);

    const requestBody: BasicInfoRequest = {
      rut: values.rut!.toString(),
      fantasyName: values.fantasyName,
      legalName: values.socialReason,
      contactFirstName: values.contactFirstName,
      contactLastName: values.contactLastName,
      phone: values.phone,
      cellphone: values.cellphone,
      email: values.email,
    };

    const response = await basicInfo(requestBody);

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;
      const state = { ...localState, ...businessData, from: pagesPaths.CommerceInformation };

      setLoading(false);

      return navigate(pagesPaths.CommerceAddress, { state });
    }
    triggerToasts(response);
    setLoading(false);
  };

  const handleSubmit = () => formRef?.current?.handleSubmit();

  const handleGoBack = () => navigate(pagesPaths.SearchCommerce);

  return (
    <>
      <Container className="information">
        <ProgressBar now={100 / 7} />
        <Row>
          <Col lg={12}>
            <h1>
              <img src={activeCommerceLogo} alt="Comercio" className="me-3" /> Información básica del comercio
            </h1>
            <br />
            <Formik
              innerRef={(formElement: any) => (formRef.current = formElement)}
              onSubmit={submitBasicInfo}
              initialValues={initialValues}
              enableReinitialize
              validationSchema={schemas.CommerceInformationSchema}
            >
              <Form>
                <Row>
                  <Input className="input col-md-6" type="text" name="rut" label="Número de RUT" readOnly />
                  <Input
                    className="input col-md-6"
                    type="text"
                    name="fantasyName"
                    label="Nombre fantasía"
                    required
                  />
                  <Input
                    className="input col-md-6"
                    type="text"
                    name="contactFirstName"
                    label="Nombre del contacto"
                    required
                  />
                  <Input
                    className="input col-md-6"
                    type="text"
                    name="contactLastName"
                    label="Apellido del contacto"
                    required
                  />
                  <Input
                    className="input col-md-6"
                    type="text"
                    name="socialReason"
                    label="Razón social"
                    required
                  />
                  <Input className="input col-md-6" type="tel" name="phone" label="Teléfono" required />
                  <Input className="input col-md-6" type="tel" name="cellphone" label="Celular" required />
                  <Input className="input col-md-6" type="email" name="email" label="Email" required />
                </Row>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={loading} />
    </>
  );
}

export default CommerceInformation;
