import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import WarningRedIcon from '../../../assets/warning-red-icon.svg';
import { PrometeoData } from '../../../types/add_commerce.types';
import { BankAccountRequest } from '../../../types/business/request';
import { CurrencyEnum } from '../../../types/enums/currency.enum';
import { AccountType, Bank, bankAccountTypes, banks } from '../../../utils/data';
import { capitalize } from '../../../utils/helpers';

interface ConfirmBankProps {
  show: boolean;
  toggleModal: () => void;
  values: BankAccountRequest;
  prometeoValues: PrometeoData[];
  handleNextStep: (values: BankAccountRequest) => void;
  prometeoError: boolean;
}

const ConfirmBankModal = ({
  show,
  toggleModal,
  handleNextStep,
  values,
  prometeoValues,
  prometeoError,
}: ConfirmBankProps) => {
  const account = (val: any, index: number) => {
    const prometeoInfo = prometeoValues?.find((b: PrometeoData) => b.accountNumber === val?.accountNumber);

    return (
      <Container>
        <Container className="card-red">
          <Row>
            <p className="strong">Cuenta bancaria {!!index && index + 1}</p>
          </Row>
          <Row>
            <Col>
              <p>Banco</p>
            </Col>
            <Col>
              <p>{banks.find((bank: Bank) => bank.id === val?.bankExternalId)?.name}</p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>Cuenta N°</p>
              <p>Tipo de cuenta</p>
              <p>Moneda</p>
              <p>Sucursal</p>
            </Col>
            <Col>
              <p>{val?.accountNumber}</p>
              <p>
                {capitalize(
                  bankAccountTypes.find(
                    (bankAccountType: AccountType) => bankAccountType.value === Number(val?.accountType),
                  )?.name,
                )}
              </p>
              <p>{+val?.accountCurrency === CurrencyEnum.URUGUAYAN_PESOS ? 'Pesos uruguayos' : 'Dólares'}</p>
              <p>{val?.accountBranch || '-'}</p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>Titular</p>
            </Col>
            <Col>
              <p>{val?.accountHolder}</p>
            </Col>
          </Row>
        </Container>
        {!prometeoError && (
          <Container className="card-red mb-0">
            <Row>
              <p className="strong">Cuenta Bancaria {!!index && index + 1} Validada</p>
            </Row>
            <Row>
              <Col>
                <p>Titular Real</p>
                <p>Moneda</p>
              </Col>
              <Col>
                <p>{prometeoInfo?.accountHolder}</p>
                <p>{prometeoInfo?.accountCurrency}</p>
              </Col>
            </Row>
          </Container>
        )}
      </Container>
    );
  };

  const buildBankContainer = () => {
    if (values?.bankAccounts && values?.bankAccounts?.length) {
      return values?.bankAccounts?.length === 2 ? (
        <Container className="cards-red">{values?.bankAccounts.map((b, i) => account(b, i))}</Container>
      ) : (
        account(values.bankAccounts[0], 0)
      );
    }
  };

  const bankContainer = buildBankContainer();

  return (
    <Modal show={show} onHide={toggleModal} centered size="lg">
      <Card className="modal-card confirm-bank-card">
        {prometeoError ? (
          <CardHeader>
            <div>
              <img src={WarningRedIcon} alt="Warning red icon" />
              <p className="no-validation-title">Validación digital de cuenta no disponible</p>
              <p className="no-validation-text">
                Para seguir con el proceso, necesitas hacer una validación manual
              </p>
            </div>
          </CardHeader>
        ) : (
          <CardHeader>
            <h1 className="mb-2">Confirmación de datos de cuenta bancaria</h1>
          </CardHeader>
        )}
        {bankContainer}
        <Card.Body>
          <Button className="primary-outlined-button w-100" onClick={() => handleNextStep(values)}>
            Los datos son correctos &gt;
          </Button>
          <Button className="primary-outlined-button w-100" onClick={toggleModal}>
            &lt; Editar datos
          </Button>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default ConfirmBankModal;
