import { Field, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import CorporatesLogo from '../../../assets/Corporates.svg';
import InvoicingLogo from '../../../assets/Invoicing.svg';
import activeCommerceLogo from '../../../assets/icon-empresa-active.png';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Input/Select';
import { default as NavigationFooter } from '../../../components/NavigationFooter/NavigationFooter';
import {
  getBusinessCategories,
  getBusinessTypes,
  getCities,
  getStates,
  getTaxPayers,
  updateBusinessAddressAndTaxes,
} from '../../../services/commerce.service';
import {
  AddressTaxesValues,
  BusinessCategory,
  City,
  CommerceAddressState,
  State,
  TaxPayer,
} from '../../../types/add_commerce.types';
import { AddressTaxesRequest } from '../../../types/business/request';
import { BusinessType, LawFormField, lawsFormFields } from '../../../utils/data';
import { sortArrayAlphabetically, triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import schemas from '../../../utils/schemas';

function CommerceAddress() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef<any>([]);
  const localState: CommerceAddressState = location.state as CommerceAddressState;
  const [states, setStates] = useState<Array<State>>([]);
  const [cities, setCities] = useState<Array<City>>([]);
  const [chosenState, setChosenState] = useState<State | null>(
    localState?.neighborhood?.city?.state || localState?.state || null,
  );
  const [businessTypes, setBusinessTypes] = useState<Array<BusinessType>>([]);

  const [businessCategories, setBusinessCategories] = useState<Array<BusinessCategory>>([]);
  const [taxPayers, setTaxPayers] = useState<Array<TaxPayer>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchStates = async () => {
    const responseStates = await getStates();
    setStates(responseStates);
  };

  const fetchCities = async (stateId: number) => {
    const responseCities = await getCities(stateId);
    setCities(responseCities.data);
  };

  const handleOnChangeState = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('addressState', +targetValue);
    setFieldValue('addressCity', '');
    setFieldValue('addressNeighborhood', '');
    const chosenState = states.find((state: State) => state.id === +targetValue);
    setChosenState(chosenState || null);
  };

  const handleOnChangeCity = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('addressCity', +targetValue);
    setFieldValue('addressNeighborhood', '');
  };

  const fetchBusinessCategories = async () => {
    const responseBusinessCategories = await getBusinessCategories();
    if (Array.isArray(responseBusinessCategories.data)) {
      const sortedResponse = sortArrayAlphabetically(responseBusinessCategories.data, 'name');
      setBusinessCategories(sortedResponse);
    }
  };

  const fetchTaxPayers = async () => {
    const responseTaxPayers = await getTaxPayers();
    if (Array.isArray(responseTaxPayers.data)) {
      const sortedResponse = sortArrayAlphabetically(responseTaxPayers.data, 'description');
      setTaxPayers(sortedResponse);
    }
  };

  const fetchBusinessTypes = async (businessCategoryId: number) => {
    const serviceResponse = await getBusinessTypes(businessCategoryId);

    setBusinessTypes(serviceResponse.data);
  };

  useEffect(() => {
    fetchBusinessCategories();
    fetchTaxPayers();

    if (localState.businessCategoryId) {
      fetchBusinessTypes(+localState.businessCategoryId);
    }
  }, []);

  useEffect(() => {
    fetchStates();
    fetchCities(localState?.city?.state?.id || localState?.city?.stateId);
  }, [localState?.city?.state]);

  useEffect(() => {
    chosenState !== null && fetchCities(chosenState.id);
  }, [chosenState]);

  const submitUpdateBusinessAddressAndTaxes = async (values: AddressTaxesValues) => {
    setLoading(true);

    const requestBody: AddressTaxesRequest = {
      neighborhoodId: +values.addressNeighborhood! || +values.addressNeighborhood?.id!,
      addressStreet: values.addressStreet,
      addressNumber: values.addressNumber,
      addressFlatNumber: values.addressFlatNumber,
      addressSolar: values.addressSolar,
      addressPlot: values.addressPlot,
      businessCategoryId: values.businessCategoryId,
      taxpayerTypeId: values.taxpayerTypeId,
      law_19210: values.law_19210,
      law_17934: values.law_17934,
      law_18083: values.law_18083,
      law_18910: values.law_18910,
      cityId: +values.addressCity?.id! || +values.addressCity!,
      businessType: +values.businessType,
    };

    const response = await updateBusinessAddressAndTaxes(localState.id!, requestBody);

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;

      setLoading(false);

      return navigate(pagesPaths.CommerceBranches, { state: { ...localState, ...businessData } });
    }
    triggerToasts(response);
    setLoading(false);
  };

  const handleSubmit = () => formRef?.current?.handleSubmit();

  const handleGoBack = () =>
    navigate(localState?.from || pagesPaths.CommerceInformation, { state: { ...localState } });

  const categoryChangeHandler = async (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    if (targetValue) {
      await fetchBusinessTypes(+targetValue);
    } else {
      setBusinessTypes([]);
    }

    setFieldValue('businessCategoryId', +targetValue);
  };

  return (
    <>
      <Container className="address">
        <ProgressBar now={(100 / 7) * 2} />
        <Row>
          <Col lg={12}>
            <Formik
              innerRef={(formElement: any) => (formRef.current = formElement)}
              initialValues={{
                addressState: localState?.city?.state! || localState?.city?.stateId || '',
                addressCity: localState?.city!,
                addressNeighborhood: localState?.neighborhood!,
                addressStreet: localState?.addressStreet! || localState?.street,
                addressNumber: localState?.addressNumber || localState?.streetNumber || '',
                addressFlatNumber: localState?.addressFlatNumber! || localState?.apartment,
                addressSolar: localState?.addressSolar!,
                addressPlot: localState?.addressPlot!,
                businessCategoryId: localState?.businessCategoryId!,
                taxpayerTypeId: localState?.taxpayerTypeId!,
                law_19210: localState?.law_19210,
                law_17934: localState?.law_17934,
                law_18083: localState?.law_18083,
                law_18910: localState?.law_18910,
                businessType: localState?.businessType || 0,
              }}
              validationSchema={schemas.AddressTaxesSchema}
              onSubmit={submitUpdateBusinessAddressAndTaxes}
            >
              <Form>
                <h1>
                  <img src={activeCommerceLogo} alt="Comercio" className="me-3" /> Domicilio principal
                </h1>
                <br />
                <Row>
                  <Select
                    className="col-md-6"
                    name="addressState"
                    handleOnChange={handleOnChangeState}
                    label="Departamento"
                    required
                  >
                    {states.map((state: State, index: number) => (
                      <option key={index} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </Select>
                  <Select
                    className="col-md-6"
                    name="addressCity"
                    handleOnChange={handleOnChangeCity}
                    label="Localidad"
                    required
                  >
                    {cities.map((city: City, index: number) => (
                      <option key={index} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </Select>
                  <Input className="input col-12" type="text" name="addressStreet" label="Calle" required />
                  <Input
                    className="input col-md-3"
                    type="text"
                    name="addressNumber"
                    label="N° de puerta"
                    required
                  />
                  <Input
                    className="input col-md-3"
                    type="number"
                    name="addressFlatNumber"
                    label="Apto (opcional)"
                  />
                  <Input
                    className="input col-md-3"
                    type="number"
                    name="addressSolar"
                    label="Solar (opcional)"
                  />
                  <Input
                    className="input col-md-3"
                    type="number"
                    name="addressPlot"
                    label="Manzana (opcional)"
                  />
                </Row>
                <Row className="row-first">
                  <h1 className="form-title row-title-space">
                    <img src={CorporatesLogo} alt="Comercio" className="me-3" /> Rubro
                  </h1>
                  <Select
                    className="col-12"
                    name="businessCategoryId"
                    handleOnChange={categoryChangeHandler}
                    label="Rubro"
                    required
                  >
                    {businessCategories?.map((category: BusinessCategory) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                  <Select className="col-12" name="businessType" label="Tipo empresa" required>
                    {businessTypes?.map((businessType: BusinessType) => (
                      <option key={businessType.value} value={businessType.value}>
                        {businessType.name}
                      </option>
                    ))}
                  </Select>
                </Row>
                <Row className="form-row">
                  <h1 className="form-title row-title-space">
                    <img src={InvoicingLogo} alt="Comercio" className="me-3" /> Información tributaria
                  </h1>
                  <Select className="col-12" name="taxpayerTypeId" label="Tipo de contribuyente" required>
                    {taxPayers?.map((taxPayer: TaxPayer) => (
                      <option key={taxPayer.id} value={taxPayer.id}>
                        {taxPayer.description}
                      </option>
                    ))}
                  </Select>
                </Row>
                <Row className="form-row-laws">
                  <p className="input-title">(Seleccionar en caso de que corresponda)</p>
                  {lawsFormFields.map((law: LawFormField, index: number) => (
                    <Col key={index}>
                      <label className="checkbox-label">
                        <Field className="checkbox" type="checkbox" name={law.fieldname} />
                        <span>{law.label}</span>
                      </label>
                    </Col>
                  ))}
                </Row>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={loading} />
    </>
  );
}

export default CommerceAddress;
