import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header/Header';
import { navigationUtils, pagesPaths } from '../utils/navigationUtils';
import ActivateAccount from './ActivateAccount/ActivateAccount';
import CommerceAddress from './AddCommerce/Address/CommerceAddress';
import CommerceBankAccount from './AddCommerce/BankAccount/CommerceBankAccount';
import CommerceBeneficiaries from './AddCommerce/Beneficiaries/CommerceBeneficiaries';
import CommerceBranches from './AddCommerce/Branches/CommerceBranches';
import CommerceCodes from './AddCommerce/Codes/CommerceCodes';
import CommerceTerminals from './AddCommerce/CommerceTerminals/CommerceTerminals';
import CommerceConditions from './AddCommerce/Conditions/CommerceConditions';
import CommerceContracts from './AddCommerce/Contracts/CommerceContracts';
import CommerceDocumentation from './AddCommerce/Documentation/CommerceDocumentation';
import CommerceInformation from './AddCommerce/Information/CommerceInformation';
import CommerceSummary from './AddCommerce/Summary/CommerceSummary';
import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import RestorePassword from './RestorePassword/RestorePassword';
import Result from './Result/Result';
import PrivateRoute from './Routes/PrivateRoute';
import SearchCommerce from './SearchCommerce/SearchCommerce';

function Main() {
  const location = useLocation();
  const isPublicPage: boolean = navigationUtils.isPublicPage(location.pathname);
  const isLoggedIn = localStorage.getItem('fullName') ? true : false;

  useEffect(() => window.scrollTo({ top: 0 }), [location.pathname]);

  return (
    <div className="main">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      {!isPublicPage && <Header />}
      <div className={isPublicPage ? 'public-content' : 'content'}>
        <Routes>
          <Route path="/activar-cuenta/:token" element={<ActivateAccount />} />
          <Route element={<PrivateRoute />}>
            <Route path={pagesPaths.SearchCommerce} element={<SearchCommerce />} />
            <Route path={pagesPaths.CommerceInformation} element={<CommerceInformation />} />
            <Route path={pagesPaths.CommerceAddress} element={<CommerceAddress />} />
            <Route path={pagesPaths.CommerceBranches} element={<CommerceBranches />} />
            <Route path={pagesPaths.CommerceBeneficiaries} element={<CommerceBeneficiaries />} />
            <Route path={pagesPaths.CommerceBankAccount} element={<CommerceBankAccount />} />
            <Route path={pagesPaths.CommerceConditions} element={<CommerceConditions />} />
            <Route path={pagesPaths.CommerceTerminals} element={<CommerceTerminals />} />
            <Route path={pagesPaths.CommerceCodes} element={<CommerceCodes />} />
            <Route path={pagesPaths.CommerceDocumentation} element={<CommerceDocumentation />} />
            <Route path={pagesPaths.CommerceSummary} element={<CommerceSummary />} />
            <Route path={pagesPaths.CommerceContracts} element={<CommerceContracts />} />
          </Route>
          <Route path="/ingresar" element={<Login />} />
          <Route path="/recuperar-password/:token" element={<RestorePassword />} />
          <Route path="/recuperar-password" element={<ResetPassword />} />
          <Route path="/resultado" element={<Result />} />
          <Route
            path="*"
            element={<Navigate to={isLoggedIn ? pagesPaths.SearchCommerce : '/ingresar'} replace />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default Main;
