import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ContractIcon from '../../../assets/Reconciliation.svg';
import ResendIcon from '../../../assets/ResendArrow.svg';
import DownloadContractIcon from '../../../assets/file-upload-solid.svg';
import BorderlessButton from '../../../components/Buttons/BorderlessButton';
import Input from '../../../components/Input/Input';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import Status from '../../../components/Status/Status';
import {
  downloadSignatureRequest,
  getBusinessContract,
  resendVaFirmaContract,
  uploadContract,
} from '../../../services/commerce.service';
import { VaFirmaContract } from '../../../types/add_commerce.types';
import { FormStatusEnum } from '../../../types/enums/form-status.enum';
import { triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import ConfirmUploadContractModal from './ConfirmUploadContractModal';

function CommerceContracts() {
  const navigation = useNavigate();
  const [resendIsLoading, setResendIsLoading] = useState<boolean>(false);
  const [uploadIsLoading, setUploadIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const [vafirmaStatus, setVafirmaStatus] = useState<any>(null);
  const localState = location.state as any;
  const formRef = useRef<any>(null);
  const [email, setEmail] = useState<string>(localState?.principalEmail);
  const [businessContract, setBusinessContract] = useState<any[]>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const uploadFile = async (e: any) => {
    setUploadIsLoading(true);
    const response = await uploadContract(e.target.files[0], localState.id);
    setUploadIsLoading(false);

    triggerToasts(
      response.result ? { status: 200, message: 'El contrato se ha subido correctamente' } : response,
    );

    vaFirmaStatus().then((data) => setVafirmaStatus(data));
  };

  const handleUploadFile = () => {
    toggleModal();

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput?.click();
    fileInput?.addEventListener('change', uploadFile);
  };

  const handleDownloadFile = async () => {
    const businessContracts: any = await getBusinessContract(localState?.id);

    for (const businessContract of businessContracts.data) {
      const contract = await downloadSignatureRequest(businessContract.vaFirmaUuid);

      const base64Response = await fetch(`data:application/pdf;base64,${contract}`);

      const blob = await base64Response.blob();

      const href = URL.createObjectURL(blob);

      const a = Object.assign(document.createElement('a'), {
        href,
        download: `${localState?.id}_${businessContract.contractType}`,
      });

      a.click();
    }
  };

  const getStatusStyle = (status: string | FormStatusEnum) => {
    switch (status) {
      case FormStatusEnum[FormStatusEnum.FINISHED]:
      case FormStatusEnum[FormStatusEnum.SIGNED]:
      case FormStatusEnum[FormStatusEnum.NOT_SENDED]:
        return 'status-green';

      case FormStatusEnum[FormStatusEnum.IN_PROCESS]:
      case FormStatusEnum[FormStatusEnum.PENDING]:
      case FormStatusEnum[FormStatusEnum.STARTED_BY_OTHER]:
        return 'status-yellow';

      default:
        return 'status-red';
    }
  };

  const getStatusValue = (status: string | FormStatusEnum) => {
    switch (status) {
      case FormStatusEnum[FormStatusEnum.SIGNED]:
      case FormStatusEnum[FormStatusEnum.FINISHED]:
      case FormStatusEnum[FormStatusEnum.NOT_SENDED]:
        return 'Firmado';

      case FormStatusEnum[FormStatusEnum.IN_PROCESS]:
      case FormStatusEnum[FormStatusEnum.PENDING]:
      case FormStatusEnum[FormStatusEnum.STARTED_BY_OTHER]:
        return 'Enviado a firmar';

      default:
        return 'Sin completar';
    }
  };

  const handleResendEmail = async () => {
    setResendIsLoading(true);
    const response = await resendVaFirmaContract(localState?.id, email);

    if (response.data) {
      setResendIsLoading(false);

      triggerToasts({ status: 200, message: 'Contrato enviado correctamente' });
    } else {
      setResendIsLoading(false);
      triggerToasts('Ocurrió un error al enviar el contrato');
    }
  };

  const submitForm = async (values: any) => console.log(values);

  const handleNext = () => navigation(pagesPaths.SearchCommerce, { state: { ...localState } });

  const vaFirmaStatus = async () => {
    const businessContracts: any = await getBusinessContract(localState?.id);
    const unsignedDocuments = businessContracts.data.some(
      (businessContract: any) => businessContract['vaFirmaStatus'] === FormStatusEnum[FormStatusEnum.PENDING],
    );
    const allDocumentsSigned = businessContracts.data.every(
      (businessContract: any) =>
        businessContract['vaFirmaStatus'] === FormStatusEnum[FormStatusEnum.NOT_SENDED] ||
        businessContract['vaFirmaStatus'] === FormStatusEnum[FormStatusEnum.SIGNED],
    );
    if (businessContracts.data.length > 0) {
      if (unsignedDocuments) {
        return FormStatusEnum[FormStatusEnum.PENDING];
      } else if (allDocumentsSigned) {
        return FormStatusEnum[FormStatusEnum.SIGNED];
      }
    }
  };

  const getBusinessContracts = async () => {
    const businessContracts: any = await getBusinessContract(localState?.id);

    return businessContracts.data;
  };

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {}, [vafirmaStatus]);

  useEffect(() => {
    vaFirmaStatus().then((data) => setVafirmaStatus(data));

    getBusinessContracts().then((data) => setBusinessContract(data));
  }, [localState?.id]);

  return (
    <>
      {showModal && (
        <ConfirmUploadContractModal
          show={true}
          toggleModal={toggleModal}
          handleUploadFile={handleUploadFile}
        />
      )}
      <Container className="contracts">
        <Row>
          <Col xs={12}>
            <h1>
              <img src={ContractIcon} alt="contract-icon" className="me-3" /> Contratos
            </h1>
            <Status status={vafirmaStatus} isStatusBorderNeeded={false} />
            <Row className="d-flex justify-content-between col-12 col-md-11 sent_contracts_box pb-4">
              <div className="d-flex flex-column pe-0">
                {businessContract?.map((contract: VaFirmaContract, index) => (
                  <div className="d-flex justify-content-between">
                    <p key={`${localState?.id}_${index}`}>
                      {contract.contractType ? contract.contractType : 'Subido a mano'}
                    </p>
                    <p
                      className={clsx(
                        'col-sm-3 col-md-3 py-2 px-3 status-value',
                        getStatusStyle(contract.vaFirmaStatus),
                      )}
                    >
                      {getStatusValue(contract.vaFirmaStatus)}
                    </p>
                  </div>
                ))}
              </div>
            </Row>
            <Row className="d-flex justify-content-between col-12 col-md-11 mt-3">
              <Formik
                initialValues={{ email: localState?.principalEmail || '' }}
                innerRef={formRef}
                onSubmit={(values) => submitForm(values)}
              >
                {({ values }) => (
                  <Form className="info semibold d-flex justify-content-end align-bottom col-12">
                    <Input
                      className="input col-12"
                      type="text"
                      name="email"
                      label="Email"
                      onBlur={() => setEmail(values.email)}
                    />
                  </Form>
                )}
              </Formik>
              <Container className="d-flex justify-content-around mt-4 buttons-container">
                <BorderlessButton
                  buttonClass="defined-border"
                  text="Reenviar"
                  icon={ResendIcon}
                  onClick={handleResendEmail}
                  loading={resendIsLoading}
                />
                <BorderlessButton
                  buttonClass="defined-border"
                  text="Descargar"
                  icon={DownloadContractIcon}
                  onClick={handleDownloadFile}
                />
                <BorderlessButton
                  buttonClass="defined-border"
                  text="Cargar contrato a mano"
                  icon={DownloadContractIcon}
                  onClick={toggleModal}
                  loading={uploadIsLoading}
                />
              </Container>
            </Row>
          </Col>
        </Row>
      </Container>
      <NavigationFooter nextHandler={handleNext} nextText="Ir al inicio" />
    </>
  );
}

export default CommerceContracts;
