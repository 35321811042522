import { Button, Card, Modal } from 'react-bootstrap';
import WarningRedIcon from '../../../assets/warning-red-icon.svg';

interface AddCommerceSuccessModalProps {
  show: boolean;
  toggleModal: () => void;
  submitConfirm: (values: any) => Promise<void>;
  formValues: {
    principalAccess: string;
    principalEmail: string;
    secondaryAccess: string;
    secondaryEmail: string;
  };
}

const BankValidationWarningModal = ({
  show,
  toggleModal,
  submitConfirm,
  formValues,
}: AddCommerceSuccessModalProps) => {
  return (
    <Modal show={show} onHide={toggleModal} centered>
      <Card className="modal-card confirm-bank-card">
        <Card.Header>
          <img className="bank-warning-icon" src={WarningRedIcon} alt="Warning red icon" />
          <h1 className="bank-warning-text bank-warning-title">
            No se ha validado la cuenta bancaria digitalmente
          </h1>
          <p className="bank-warning-text">
            La cuenta bancaria ha sido validada a través de un proceso manual
          </p>
        </Card.Header>
        <Card.Body>
          <Button className="primary-outlined-button w-100" onClick={() => submitConfirm(formValues)}>
            Finalizar alta de comercio &gt;
          </Button>
          <Button className="primary-outlined-button w-100" onClick={toggleModal}>
            &lt; Volver
          </Button>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default BankValidationWarningModal;
