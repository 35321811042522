import { FormStepEnum } from '../types/enums/form-step.enum';

interface BusinessPage {
  step: FormStepEnum;
  path: string;
}

export const pagesPaths: Record<string, string> = {
  SearchCommerce: '/buscar_comercio',
  CommerceInformation: '/info_comercio',
  CommerceAddress: '/domicilio',
  CommerceBranches: '/sucursales',
  CommerceBeneficiaries: '/beneficiarios',
  CommerceBankAccount: '/cuenta_bancaria',
  CommerceConditions: '/tarifario',
  CommerceTerminals: '/red_terminales',
  CommerceCodes: '/codigos_comercio',
  CommerceDocumentation: '/documentacion',
  CommerceSummary: '/resumen_alta',
  CommerceContracts: '/contratos',
};

const publicPages = ['/activar-cuenta', '/ingresar', '/recuperar-password', '/resultado'];

const isPublicPage = (page: string): boolean => {
  return publicPages.some((p) => page.includes(p));
};

export const navigationUtils = { isPublicPage };

export const addBusinessPages: BusinessPage[] = [
  { step: FormStepEnum.NONE, path: pagesPaths.SearchCommerce },
  { step: FormStepEnum.FIRST, path: pagesPaths.CommerceInformation },
  { step: FormStepEnum.SECOND, path: pagesPaths.CommerceAddress },
  { step: FormStepEnum.THIRD, path: pagesPaths.CommerceBranches },
  { step: FormStepEnum.FOURTH, path: pagesPaths.CommerceBeneficiaries },
  { step: FormStepEnum.FIFTH, path: pagesPaths.CommerceBankAccount },
  { step: FormStepEnum.SIXTH, path: pagesPaths.CommerceConditions },
  { step: FormStepEnum.SEVENTH, path: pagesPaths.CommerceTerminals },
  { step: FormStepEnum.EIGHTH, path: pagesPaths.CommerceCodes },
  { step: FormStepEnum.NINETH, path: pagesPaths.CommerceDocumentation },
  { step: FormStepEnum.TENTH, path: pagesPaths.CommerceSummary },
  { step: FormStepEnum.DONE, path: pagesPaths.CommerceContracts },
];

export const getAddBusinessPage = (step: FormStepEnum) => {
  if (step === FormStepEnum.NONE || step === FormStepEnum.DONE) {
    return null;
  }
  const path = addBusinessPages.find((businessPage: BusinessPage) => businessPage.step === step)?.path;
  return path;
};

export const getLocalStorageValues = () => {
  const savedValues: any = {};
  Object.keys(localStorage).forEach((key: string) => {
    const previousValue: string | null = localStorage.getItem(key);
    if (!!previousValue) {
      savedValues[key] = previousValue;
    }
  });
  return savedValues;
};

export const handleOnBlur = (event: any, initialValue: any) => {
  if (initialValue !== event.target.value) {
    localStorage.setItem(event.target.id, event.target.value);
  }
};
