import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../../assets/logo-getnet-full@2x.png';
import Logout from '../../screens/Logout/Logout';
import { pagesPaths } from '../../utils/navigationUtils';

function Header() {
  const fullName = localStorage.getItem('fullName');
  const [show, setShow] = useState<boolean>(false);

  return (
    <Navbar expand="lg" sticky="top">
      <Container className="px-lg-0">
        <Navbar.Brand>
          <Nav.Link className="p-0" href={pagesPaths.SearchCommerce}>
            <img src={logo} alt="Getnet" width={115} />
          </Nav.Link>
        </Navbar.Brand>
        <Nav>
          <div className="d-flex justify-content-start">
            <div className="navbar-username mx-4 d-none d-md-block">
              <FontAwesomeIcon icon={faUser as IconProp} /> {fullName || ''}
            </div>
            <Nav.Link className="px-lg-0" onClick={() => setShow(true)}>
              <FontAwesomeIcon icon={faSignOut as IconProp} />
              Cerrar sesión
            </Nav.Link>
          </div>
        </Nav>
        <Logout setShow={setShow} show={show} />
      </Container>
    </Navbar>
  );
}

export default Header;
