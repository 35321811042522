import * as Yup from 'yup';
import { triggerToasts } from './helpers';
import { Bank } from './data';
import schemas from './schemas';

export const doRefsSubmissions = (formRefs: any): boolean => {
  let shouldContinue = true;

  formRefs?.current?.forEach((formElement: any) => {
    formElement?.handleSubmit();
    if (formElement?.errors && Object.keys(formElement.errors).length) {
      shouldContinue = false;
    }
  });

  if (!shouldContinue) {
    triggerToasts('Revise los campos');
  }
  return shouldContinue;
};

export const updateSchemaWithBank = (bank: Bank) => {
  const bankAccountLengths = bank?.lengths || 0;

  const accountNumberUpdatedSchema = Yup.string()
    .test(
      'bankAccountLengths',
      !bank
        ? 'Debe seleccionar un banco'
        : `Debe ser de ${
            Array.isArray(bankAccountLengths)
              ? `${Math.min(...bankAccountLengths)} o ${Math.max(...bankAccountLengths)}`
              : bankAccountLengths
          } caracteres`,
      (value: any) => {
        if (bank.lengths === null) return true;
        if (!value) return false;
        return Array.isArray(bankAccountLengths)
          ? !!bankAccountLengths.find((accountLength) => value.length === accountLength)
          : value.length === bankAccountLengths;
      },
    )
    .matches(/^\d{1,}$/, schemas.numberOnlyErrorString)
    .test('bankAccountPositive', 'Debe ser número positivo', (value: any) => +value > 0)
    .ensure()
    .required('campo requerido');

  return accountNumberUpdatedSchema;
};
