import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-getnet-full@2x.png';
import Input from '../../components/Input/Input';
import { login } from '../../services/user.service';
import { pagesPaths } from '../../utils/navigationUtils';
import schemas from '../../utils/schemas';

interface LoginValues {
  email: string;
  password: string;
}

function Login() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const navigate = useNavigate();

  const submitLogin = async (values: LoginValues) => {
    if (true) {
      // THIS IS TEMPORARY - HERE WE NEED TO VALIDATE captchaToken like this: if (captchaToken) {}
      const loginResponse = await login(values.email, values.password);

      if (loginResponse.result) {
        const fullName = `${loginResponse.data.manager.firstName} ${loginResponse.data.manager.lastName}`;
        localStorage.clear();
        localStorage.setItem('fullName', fullName);
        localStorage.setItem(
          'canAccessPersonalizedTariff',
          loginResponse.data.manager.canAccessPersonalizedTariff,
        );
        localStorage.setItem('mangerType', loginResponse.data.manager.managerType);
        localStorage.setItem('tariffRestriction', loginResponse.data.manager.tariffRestriction);

        navigate(pagesPaths.SearchCommerce);
      } else {
        const errorMessageES = loginResponse.data.showMessage !== null && loginResponse.data.showMessage.ES;
        setLoginErrorMessage(errorMessageES);
      }
    } else {
      setLoginErrorMessage('Debes completar el captcha');
    }
  };

  const captchaHandler = (token: string | null) => {
    setCaptchaToken(token);
    setLoginErrorMessage(null);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          <Card className="card">
            <CardHeader>
              <img src={logo} alt="Getnet" style={{ width: 152 }} />
              <h1>Onboarding Ejecutivos</h1>
            </CardHeader>
            <Card.Body>
              <p>Ingresar al sistema</p>
              <Formik
                onSubmit={submitLogin}
                initialValues={{ email: '', password: '' }}
                validationSchema={schemas.LoginSchema}
              >
                <Form>
                  <Input className="position-relative pb-3" type="email" name="email" placeholder="Email" />
                  <Input
                    className="position-relative pb-3"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Contraseña"
                  >
                    <span
                      className={showPassword ? 'icon-show-password' : 'icon-hide-password'}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </Input>
                  {/* <div className="captcha-container">
                    <ReCAPTCHA onChange={captchaHandler} sitekey={captchaConfig.webkey} />
                  </div> */}
                  {loginErrorMessage !== null && <p className="login-error">{loginErrorMessage}</p>}
                  <Button type="submit" className="primary-outlined-button w-100">
                    Ingresar
                  </Button>
                </Form>
              </Formik>
            </Card.Body>
            <Link to="/recuperar-password" className="forgot-password">
              ¿Olvidaste tu contraseña?
            </Link>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
