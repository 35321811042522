import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/animations/loading.json';

interface BorderlessButtonProps {
  onClick: () => void;
  text: string;
  icon: string;
  type?: 'button' | 'submit';
  buttonClass?: string;
  loading?: boolean;
}

function BorderlessButton({
  onClick,
  text,
  icon,
  type = 'button',
  buttonClass,
  loading,
}: BorderlessButtonProps) {
  const borderlessButtonContent = loading ? (
    <Lottie animationData={loadingAnimation} loop={true} style={{ height: 30, width: 30, margin: 'auto' }} />
  ) : (
    <button type={type} className={`borderless-button ${buttonClass}`} onClick={onClick}>
      <img alt={text} src={icon} /> {text}
    </button>
  );

  return borderlessButtonContent;
}

export default BorderlessButton;
