import { Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import management from '../../assets/Consumption-management.svg';
import BusinessInfo from '../../components/BusinessInfo/BusinessInfo';
import Input from '../../components/Input/Input';
import NavigationFooter from '../../components/NavigationFooter/NavigationFooter';
import Spinner from '../../components/Spinner/Spinner';
import Status from '../../components/Status/Status';
import { getCommerce, getDgiBusinessInfo, updateBusinessExecutive } from '../../services/commerce.service';
import {
  AddCommerceSixthAndFinalStepLocationState,
  Contract,
  EcommerceTariffValues,
} from '../../types/add_commerce.types';
import { FormStatusEnum } from '../../types/enums/form-status.enum';
import { FormStepEnum } from '../../types/enums/form-step.enum';
import { getAddBusinessPage, pagesPaths } from '../../utils/navigationUtils';
import schemas from '../../utils/schemas';

interface SearchCommerceValues {
  rut: number | string;
}

export interface CommerceStatus {
  state: FormStatusEnum;
  step: FormStepEnum;
}

function SearchCommerce() {
  const [commerceExists, setCommerceExists] = useState<boolean | null>(null);
  const [commerceMessage, setCommerceMessage] = useState<string>('');
  const [commerceRut, setCommerceRut] = useState<number>(0);
  const [businessData, setBusinessData] = useState<AddCommerceSixthAndFinalStepLocationState | any>({});
  const [commerceStatus, setCommerceStatus] = useState<CommerceStatus | null>(null);
  const [commerceId, setCommerceId] = useState<number>(0);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const ecommerceAndTariffsForm = useRef<FormikProps<EcommerceTariffValues>>(null);
  const [uploadedContract, setUploadedContract] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const submitSearchCommerce = async (values: SearchCommerceValues) => {
    setShowSpinner(true);
    const getCommerceResponse = await getCommerce(Number(values.rut));
    setShowSpinner(false);

    if (getCommerceResponse.status !== 401) {
      setCommerceRut(Number(values.rut));

      if (getCommerceResponse.data) {
        setCommerceMessage(getCommerceResponse.data.message);
        setCommerceExists(getCommerceResponse.data.rutExists);
        setCommerceStatus({ state: getCommerceResponse.data.state, step: getCommerceResponse.data.step });
        setBusinessData(getCommerceResponse?.data?.businessData || {});
        setCommerceId(+getCommerceResponse.data.businessData.id);

        if (getCommerceResponse.data.businessData) {
          setBusinessData(getCommerceResponse.data.businessData || {});
          setCommerceId(+getCommerceResponse.data.businessData.id);
        }
      }
    } else {
      localStorage.removeItem('fullName');
    }
  };

  const handleSaveData = async () =>
    ecommerceAndTariffsForm.current && ecommerceAndTariffsForm.current.handleSubmit();

  const handleMoreInfo = async () => {
    const path = getAddBusinessPage(commerceStatus?.step!);

    if (commerceStatus?.state === FormStatusEnum.FREE) {
      await updateBusinessExecutive(+commerceId);
    }

    if (path) {
      setCommerceStatus(null);

      const getDgiInfo = path === pagesPaths.CommerceInformation && !Object.keys(businessData).length;
      if (getDgiInfo) {
        setShowSpinner(true);
        const dgiInfo = await getDgiBusinessInfo(+commerceRut);
        setShowSpinner(false);

        if (dgiInfo.status >= 400) {
          return navigate(path, { state: { commerceRut, from: pagesPaths.SearchCommerce } });
        } else {
          return navigate(path, {
            state: { commerceRut, ...businessData, ...dgiInfo.data, from: pagesPaths.SearchCommerce },
          });
        }
      }

      const hideEmailSection: boolean =
        path === pagesPaths.CommerceSummary &&
        businessData.status === FormStatusEnum[FormStatusEnum.FINISHED] &&
        businessData?.contracts?.some((c: Contract) => c.vaFirmaStatus !== 'SIGNED');

      navigate(path, {
        state: { commerceRut, ...businessData, from: pagesPaths.SearchCommerce, hideEmailSection },
      });
    }
  };

  const showFooter =
    (commerceExists && commerceStatus?.state === FormStatusEnum.IN_PROCESS) ||
    (commerceExists && commerceStatus?.state === FormStatusEnum.FINISHED) ||
    (!commerceExists && commerceStatus?.state === FormStatusEnum.EMPTY) ||
    (commerceExists && commerceStatus?.state === FormStatusEnum.FREE);

  const showBusinessInfo =
    !!Object.keys(businessData).length &&
    (commerceStatus?.step === FormStepEnum.SECOND || commerceStatus?.step === FormStepEnum.TENTH);

  const generateStatus = (businessData: AddCommerceSixthAndFinalStepLocationState) => {
    if (uploadedContract) {
      return FormStatusEnum[FormStatusEnum.FINISHED];
    } else {
      if (businessData.status === FormStatusEnum[FormStatusEnum.FINISHED]) {
        const contractsAreSigned = businessData?.contracts?.every(
          (contract: Contract) =>
            contract.vaFirmaStatus === 'SIGNED' || contract.vaFirmaStatus === 'NOT_SENDED',
        );

        return contractsAreSigned
          ? FormStatusEnum[FormStatusEnum.FINISHED]
          : FormStatusEnum[FormStatusEnum.IN_PROCESS];
      }

      return businessData.status;
    }
  };

  const contractsSigned: boolean = businessData?.contracts?.every(
    (contract: Contract) => contract.vaFirmaStatus === 'SIGNED' || contract.vaFirmaStatus === 'NOT_SENDED',
  );
  const showSaveDataButton: boolean =
    contractsSigned && !businessData?.hasEcommerce && businessData?.principalEmail;

  const handleStatus = () => {
    setUploadedContract(!uploadedContract);
  };

  return (
    <>
      <Container className="search-commerce">
        <Row>
          <Col sm={12}>
            <h1 className="mb-5">
              <img src={management} alt="Comercio" className="me-3" />
              Búsqueda de comercio
            </h1>
            <Formik
              initialValues={{ rut: '' }}
              validationSchema={schemas.SearchCommerceSchema}
              onSubmit={submitSearchCommerce}
            >
              <Form className="row align-items-end">
                <Input
                  type="tel"
                  name="rut"
                  placeholder="RUT"
                  className="flex-grow-1 col-9 col-md-11 pb-3"
                  label="Ingrese número de RUT"
                />
                <Col sm={12} md={1}>
                  <Button
                    type="submit"
                    variant="outline-danger"
                    className="primary-outlined-button search-button"
                  >
                    Buscar
                  </Button>
                </Col>
              </Form>
            </Formik>
            <Spinner visible={showSpinner} />
            {commerceMessage && !showSpinner && <p className="search-description">{commerceMessage}</p>}
            {businessData.status && !showSpinner && <Status status={generateStatus(businessData)} />}
            {showBusinessInfo && !showSpinner && (
              <div className="confirmation col-sm-12 col-md-11">
                <BusinessInfo
                  businessData={businessData as AddCommerceSixthAndFinalStepLocationState}
                  showAttachments
                  checkboxState={businessData.surchargedFee}
                  anticipatePayments={businessData.anticipatePayments}
                  comment={businessData.comment}
                  status={commerceStatus.state}
                  ecommerceAndTariffsForm={ecommerceAndTariffsForm}
                  commerceId={+commerceId}
                  comesFromSearchCommerce
                  handleStatus={handleStatus}
                  uploadedContract={uploadedContract}
                  setIsLoading={setIsLoading}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {showFooter && (
        <NavigationFooter
          nextHandler={handleMoreInfo}
          nextText={!commerceExists ? 'Dar de alta' : showBusinessInfo ? 'Más información' : 'Continuar'}
          previousText={showSaveDataButton ? 'Guardar información' : false}
          previousHandler={showSaveDataButton ? handleSaveData : false}
          showPreviousIcon={showSaveDataButton ? false : undefined}
          loading={isLoading}
        />
      )}
    </>
  );
}

export default SearchCommerce;
