import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { resetPassword } from '../../services/user.service';
import resultMessage from '../../utils/resultMessage';

interface ResultLocationState {
  email: string;
  response: boolean;
  activeUserResponse: boolean;
  isFromRestorePassword?: boolean;
  isFromRestorePasswordEmailSender?: boolean;
}

function Result() {
  const location = useLocation();
  const localState: ResultLocationState = location.state as ResultLocationState;

  function resendEmail() {
    resetPassword(localState.email as string);
  }

  function getText(property: string) {
    return localState.isFromRestorePassword
      ? resultMessage.successResetPassword[property as keyof typeof resultMessage.successResetPassword]
      : (localState.response &&
          resultMessage.successChangePassword[
            property as keyof typeof resultMessage.successChangePassword
          ]) ||
          (localState.activeUserResponse &&
            resultMessage.successActivateAccount[
              property as keyof typeof resultMessage.successActivateAccount
            ]) ||
          (!localState.response &&
            !localState.activeUserResponse &&
            resultMessage.errorChangePassword[property as keyof typeof resultMessage.errorChangePassword]);
  }

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          <div className="result">
            <h1 className="title">{getText('title')}</h1>
            <p className="description">{getText('text')}</p>

            {localState.response && (
              <p className="send-again-email">
                {resultMessage.successChangePassword.sendEmailAgain}{' '}
                <Link className="back-to-login" to="/" onClick={resendEmail}>
                  Reenviar
                </Link>
              </p>
            )}
            <Link to="/ingresar" className="btn btn-outline-white">
              {localState.isFromRestorePasswordEmailSender ? 'Volver a inicio' : 'Iniciar sesión'}
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Result;
