import * as Yup from 'yup';
import { PhoneCountryCodesEnum } from '../types/enums/phone-country-codes.enum';
import { ShippingTypeEnum } from '../types/enums/shipping-type.enum';

const requiredString = 'Campo requerido';
const requiredOption = 'Selecciona una opción';
const mailString = 'Formato de mail inválido';
const passwordLength = 8;
const rutLength = 12;
const rutLengthErrorString = `Solo acepta ${rutLength} digitos numéricos`;
const passwordLengthErrorString = `Tu contraseña debe tener al menos ${passwordLength} caracteres, una mayúscula, una minúscula y un número`;
const passwordNotEqual = 'La contraseña y la confirmación deben ser iguales.';
const phoneErrorString = 'Deben ser 9 numeros, iniciando con 0';
const numberOnlyErrorString = 'Sólo acepta números';
const negativeErrorString = 'Debe ser número positivo';
const documentNumberLengthErrorString = 'Debe tener 7 u 8 caracteres';
const addressAlphanumericErrorString = 'Debe contener al menos 1 número';
const brouAccountNumber = 'Debe tener 9 caracteres';
const brouSubAccount = 'Debe tener 5 caracteres';
const citiAccountNumber = 'Debe tener 10 caracteres';
const bandesAccountNumber = 'Debe tener 6 caracteres';
const bandesAccountBranch = 'Debe tener 3 caracteres';
const itauAccountNumber = 'Debe tener 7 caracteres';
const scotiabankAccountNumber = 'Debe tener 7 o 10 caracteres';
const tarjetaDLength = 'Debe tener 4 o 5 caracteres';
const nacionAccountNumber = 'Debe tener como máximo 12 caracteres';
const heritageSubAccount = 'Debe tener 2 caracteres';
const santanderAccountBranch = 'Debe tener 4 caracteres';
const commerceCodesLength = (num: number) => `Debe tener ${num} caracteres`;
const accountHolderLength = 'Debe tener máximo 100 caracteres';
const deliveryTimeLengthErrorString = 'Debe tener máximo 50 caracteres';
const uyPhoneNumber = 'Debe tener entre 8 y 9 caracteres';
const arPhoneNumber = 'Debe tener entre 8 y 12 caracteres, incluyendo el código de área sin el 15';
const brPhoneNumber = 'Debe tener 11 caracteres';
const pyPhoneNumber = 'Debe tener 9 caracteres';

const numberSchemaForwardObjectRequired = Yup.number()
  .transform((_, val) => val?.id || Number(val))
  .positive(requiredString)
  .required(requiredString);

const numberSchemaOptionalNullable = Yup.number()
  .transform((_, val) => (val ? Number(val) : null))
  .positive(negativeErrorString)
  .nullable(true);

const numberSchemaUploadFile = Yup.number()
  .transform((_, val) => (val ? Number(val) : 0))
  .positive('Debe cargar un archivo');

const stringCelphoneNumberSchema = Yup.string()
  .matches(/^0{1}[0-9]{8}$/, phoneErrorString)
  .required(requiredString);

const requiredStringNullTransform = Yup.string()
  .transform((_, val) => {
    if (typeof val === 'number') return val.toString();
    return val ? val : '';
  })
  .required(requiredString);

const documentSchema = Yup.string()
  .min(7, documentNumberLengthErrorString)
  .max(8, documentNumberLengthErrorString)
  .required(requiredString);

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
  password: Yup.string().required(requiredString),
});

const ActivationSchema = Yup.object().shape({
  document: Yup.number().typeError(requiredString).required(requiredString),
  password: Yup.string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, passwordLengthErrorString)
    .required(requiredString),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], passwordNotEqual)
    .required(requiredString),
});

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email(mailString).required(requiredString),
});

const SearchCommerceSchema = Yup.object().shape({
  rut: Yup.string()
    .matches(/^[0-9]{12}$/, rutLengthErrorString)
    .ensure()
    .required(requiredString),
});

const CommerceInformationSchema = Yup.object().shape({
  fantasyName: Yup.string().required(requiredString),
  socialReason: Yup.string().required(requiredString),
  contactFirstName: Yup.string().required(requiredString),
  contactLastName: Yup.string().required(requiredString),
  phone: Yup.string()
    .matches(/^\d{1,}$/, numberOnlyErrorString)
    .required(requiredString),
  cellphone: stringCelphoneNumberSchema,
  email: Yup.string().email(mailString).required(requiredString),
});

const AddressTaxesSchema = Yup.object().shape({
  addressState: numberSchemaForwardObjectRequired,
  addressCity: numberSchemaForwardObjectRequired,
  addressStreet: requiredStringNullTransform,
  addressNumber: Yup.string()
    .matches(/(\d){1}/, addressAlphanumericErrorString)
    .required(requiredString),
  addressFlatNumber: numberSchemaOptionalNullable,
  addressSolar: numberSchemaOptionalNullable,
  addressPlot: numberSchemaOptionalNullable,
  businessCategoryId: Yup.string().ensure().required(requiredString),
  taxpayerTypeId: Yup.string().ensure().required(requiredString),
  law_19210: Yup.boolean(),
  law_17934: Yup.boolean(),
  law_18083: Yup.boolean(),
  law_18910: Yup.boolean(),
});

const ActivateCommerceSecondStep = Yup.object().shape({
  fantasyName: Yup.string().required(requiredString),
  state: numberSchemaForwardObjectRequired,
  city: numberSchemaForwardObjectRequired,
  addressStreet: Yup.string().required(requiredString),
  addressNumber: Yup.string()
    .matches(/(\d){1}/, addressAlphanumericErrorString)
    .required(requiredString),
  addressFlatNumber: numberSchemaOptionalNullable,
  addressSolar: numberSchemaOptionalNullable,
  addressPlot: numberSchemaOptionalNullable,
});

const ActivateCommerceSecondStepSameAddress = Yup.object().shape({
  fantasyName: Yup.string().required(requiredString),
});

const ActivateCommerceThirdStep = Yup.object().shape({
  documentType: Yup.number().typeError(requiredOption).required(requiredString),
  documentNumber: documentSchema,
  firstName: Yup.string().required(requiredString),
  lastName: Yup.string().required(requiredString),
  participationPercentage: Yup.number()
    .typeError('El % de participación es un campo numérico')
    .required(requiredString)
    .positive(negativeErrorString)
    .max(100, '100% es el máximo'),
  isPep: Yup.boolean().required(requiredString),
  isRelative: Yup.boolean().required(requiredString),
  branchOfficeId: Yup.number().typeError(requiredOption).required(requiredString),
});

const ActivateCommerceThirdStepFamiliarPep = Yup.object().shape({
  relativeDocumentType: Yup.number().typeError(requiredOption).required(requiredString),
  relativeDocumentNumber: documentSchema,
  relativeFirstName: Yup.string().required(requiredString),
  relativeLastName: Yup.string().required(requiredString),
});

const CommerceBankAccountSchema = Yup.object().shape({
  bankAccountTrackingNumber: Yup.string().when('accountsQuantity', {
    is: (accountsQuantity: number) => accountsQuantity === 0,
    then: Yup.string().required(requiredString),
  }),

  accountsQuantity: Yup.number(),

  bankAccounts: Yup.array().when(['accountsQuantity'], {
    is: (accountsQuantity: number) => accountsQuantity > 0,
    then: Yup.array().of(
      Yup.object().shape({
        bankExternalId: Yup.string().required(requiredOption),
        accountCurrency: Yup.string().required(requiredOption),
        accountHolder: Yup.string()
          .required(requiredString)
          .matches(/^([a-zA-Z0-9_-\s]){1,100}$/, accountHolderLength),
        accountType: Yup.string().required(requiredString),
        isActive: Yup.boolean().required(requiredOption),

        accountNumber: Yup.string()
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '50',
            then: Yup.string().trim().length(9, brouAccountNumber).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '51',
            then: Yup.string().trim().length(10, citiAccountNumber).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '52',
            then: Yup.string()
              .matches(/^\d{7}$|^\d{10}$/, scotiabankAccountNumber)
              .required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '53',
            then: Yup.string().trim().length(7, itauAccountNumber).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '54',
            then: Yup.string().trim().length(9, brouAccountNumber).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '55',
            then: Yup.string().trim().length(12, nacionAccountNumber).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '56',
            then: Yup.string().trim().length(6, bandesAccountNumber).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '57',
            then: Yup.string().trim().length(12, nacionAccountNumber).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '58',
            then: Yup.string().trim().length(10, citiAccountNumber).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '60',
            then: Yup.string().trim().length(7, itauAccountNumber).required(requiredString),
          }),

        accountBranch: Yup.string()
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '55',
            then: Yup.string().trim().length(4, santanderAccountBranch).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '56',
            then: Yup.string().trim().length(3, bandesAccountBranch).required(requiredString),
          }),

        subAccount: Yup.string()
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '50',
            then: Yup.string().trim().length(5, brouSubAccount).required(requiredString),
          })
          .when('bankExternalId', {
            is: (bankExternalId: string) => bankExternalId === '60',
            then: Yup.string().trim().length(2, heritageSubAccount).required(requiredString),
          }),
      }),
    ),
  }),
});

const CommerceCodesSchema = Yup.object().shape({
  commerceCodes: Yup.object().shape({
    amex: Yup.string().trim().length(10, commerceCodesLength(10)).nullable(),
    anda: Yup.string().trim().length(12, commerceCodesLength(12)).nullable(),
    cabal: Yup.string().trim().length(11, commerceCodesLength(11)).nullable(),
    cabalDolar: Yup.string().trim().length(11, commerceCodesLength(11)).nullable(),
    clubDelEste: Yup.string().trim().length(4, commerceCodesLength(4)).nullable(),
    creditel: Yup.string().trim().length(6, commerceCodesLength(6)).nullable(),
    diners: Yup.string().trim().length(8, commerceCodesLength(8)).nullable(),
    oca: Yup.string().trim().length(6, commerceCodesLength(6)).nullable(),
    passcard: Yup.string().trim().length(5, commerceCodesLength(5)).nullable(),
    tarjetaD: Yup.string().trim().min(4, tarjetaDLength).max(5, tarjetaDLength).nullable(),
    cdlcQr: Yup.string().trim().length(6, commerceCodesLength(6)).nullable(),
  }),
  installments: Yup.string().oneOf(['yes', 'no']).required(requiredString),
  tips: Yup.string().oneOf(['yes', 'no']).required(requiredString),
  ivaType: Yup.string().oneOf(['0', '1', '2', '4']).required(requiredString),
});

const CommerceSummarySchema = Yup.object().shape({
  principalEmail: Yup.string().email(mailString).required(requiredString),
  secondaryEmail: Yup.string().email(mailString).nullable(),
  secondaryAccess: Yup.string().oneOf(['yes', 'no']).nullable(),
  sendContractsOption: Yup.string().required(requiredString),
  phoneNumberCountryCode: Yup.string().when('sendContractsOption', {
    is: (sendContractsOption: string) => sendContractsOption === 'BOTH',
    then: Yup.string().trim().required(requiredString),
  }),
  phoneNumber: Yup.string()
    .when('sendContractsOption', {
      is: (sendContractsOption: string) => sendContractsOption === 'BOTH',
      then: Yup.string().trim().required(requiredString),
    })
    .when('phoneNumberCountryCode', {
      is: (phoneNumberCountryCode: string) => phoneNumberCountryCode === PhoneCountryCodesEnum.UY,
      then: (schema) => schema.min(8, uyPhoneNumber).max(9, uyPhoneNumber),
    })
    .when('phoneNumberCountryCode', {
      is: (phoneNumberCountryCode: string) => phoneNumberCountryCode === PhoneCountryCodesEnum.AR,
      then: (schema) => schema.min(8, arPhoneNumber).max(12, arPhoneNumber),
    })
    .when('phoneNumberCountryCode', {
      is: (phoneNumberCountryCode: string) => phoneNumberCountryCode === PhoneCountryCodesEnum.BR,
      then: (schema) => schema.length(11, brPhoneNumber),
    })
    .when('phoneNumberCountryCode', {
      is: (phoneNumberCountryCode: string) => phoneNumberCountryCode === PhoneCountryCodesEnum.PY,
      then: (schema) => schema.length(9, pyPhoneNumber),
    }),
});

const ActivateCommerceSixthStepFirst = Yup.object().shape({
  cardFormDGI: numberSchemaUploadFile,
});

const ActivateCommerceSixthStepIds = Yup.object().shape({
  frontDocument: numberSchemaUploadFile,
  backDocument: numberSchemaUploadFile,
});

const CommerceConditionsSchema = Yup.object().shape({
  currency: Yup.string().required(requiredString),
  installmentsWithRecharge: Yup.boolean().required(requiredString),
  anticipatePayments: Yup.boolean().required(requiredString),
});

const CommerceTerminalsSchema = Yup.object().shape({
  automaticDebit: Yup.boolean().required(requiredString),
  integration: Yup.string().required(requiredString),
  commercialCenter: Yup.string().required(requiredString),
  posSale: Yup.string().required(requiredString),
  promotion: Yup.string().when(['posSale', 'commercialCenter', 'integration'], {
    is: (posSale: string, commercialCenter: string, integration: string) =>
      posSale === 'SI' && commercialCenter === 'NO' && +integration === 0,

    then: Yup.string().required(requiredString),
  }),

  addressStreet: Yup.string().when(
    ['shippingType', 'withdrawInNAD', 'integration', 'commercialCenter', 'posSale'],
    {
      is: (
        shippingType: ShippingTypeEnum,
        withdrawInNAD: boolean,
        integration: string,
        commercialCenter: string,
        posSale: string,
      ) =>
        shippingType === ShippingTypeEnum.NAD &&
        !withdrawInNAD &&
        +integration === 0 &&
        commercialCenter === 'NO' &&
        posSale === 'SI',

      then: Yup.string().required(requiredString),
    },
  ),

  addressNumber: Yup.string().when(
    ['shippingType', 'withdrawInNAD', 'integration', 'commercialCenter', 'posSale'],
    {
      is: (
        shippingType: ShippingTypeEnum,
        withdrawInNAD: boolean,
        integration: string,
        commercialCenter: string,
        posSale: string,
      ) =>
        shippingType === ShippingTypeEnum.NAD &&
        !withdrawInNAD &&
        +integration === 0 &&
        commercialCenter === 'NO' &&
        posSale === 'SI',
      then: Yup.string().required(requiredString),
    },
  ),

  department: Yup.string().when(
    ['shippingType', 'withdrawInNAD', 'integration', 'commercialCenter', 'posSale'],
    {
      is: (
        shippingType: ShippingTypeEnum,
        withdrawInNAD: boolean,
        integration: string,
        commercialCenter: string,
        posSale: string,
      ) =>
        shippingType === ShippingTypeEnum.NAD &&
        !withdrawInNAD &&
        +integration === 0 &&
        commercialCenter === 'NO' &&
        posSale === 'SI',
      then: Yup.string().required(requiredString),
    },
  ),

  addressCity: Yup.string().when(
    ['shippingType', 'withdrawInNAD', 'integration', 'commercialCenter', 'posSale'],
    {
      is: (
        shippingType: ShippingTypeEnum,
        withdrawInNAD: boolean,
        integration: string,
        commercialCenter: string,
        posSale: string,
      ) =>
        shippingType === ShippingTypeEnum.NAD &&
        !withdrawInNAD &&
        +integration === 0 &&
        commercialCenter === 'NO' &&
        posSale === 'SI',
      then: Yup.string().required(requiredString),
    },
  ),

  phoneNumber: Yup.string().when(
    ['shippingType', 'withdrawInNAD', 'integration', 'commercialCenter', 'posSale'],
    {
      is: (
        shippingType: ShippingTypeEnum,
        withdrawInNAD: boolean,
        integration: string,
        commercialCenter: string,
        posSale: string,
      ) =>
        shippingType === ShippingTypeEnum.NAD &&
        !withdrawInNAD &&
        +integration === 0 &&
        commercialCenter === 'NO' &&
        posSale === 'SI',
      then: Yup.string()
        .matches(/^\d{1,}$/, numberOnlyErrorString)
        .required(requiredString),
    },
  ),

  deliveryTime: Yup.string().when(
    ['shippingType', 'withdrawInNAD', 'integration', 'commercialCenter', 'posSale'],
    {
      is: (
        shippingType: ShippingTypeEnum,
        withdrawInNAD: boolean,
        integration: string,
        commercialCenter: string,
        posSale: string,
      ) =>
        shippingType === ShippingTypeEnum.NAD &&
        !withdrawInNAD &&
        +integration === 0 &&
        commercialCenter === 'NO' &&
        posSale === 'SI',
      then: Yup.string().required(requiredString).max(50, deliveryTimeLengthErrorString),
    },
  ),
});

const schemas = {
  ResetPasswordSchema,
  LoginSchema,
  SearchCommerceSchema,
  ActivationSchema,
  CommerceInformationSchema,
  AddressTaxesSchema,
  ActivateCommerceSecondStep,
  ActivateCommerceSecondStepSameAddress,
  ActivateCommerceThirdStep,
  ActivateCommerceThirdStepFamiliarPep,
  CommerceBankAccountSchema,
  CommerceCodesSchema,
  CommerceSummarySchema,
  ActivateCommerceSixthStepFirst,
  ActivateCommerceSixthStepIds,
  numberOnlyErrorString,
  CommerceConditionsSchema,
  CommerceTerminalsSchema,
};

export default schemas;
