import { Button, Card, Modal } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import ExclamationMark from '../../../assets/exclamation-mark.svg';

interface ConfirmNotValidatedEmailModalProps {
  show: boolean;
  toggleModal: () => void;
  handleSendContracts: () => void;
  handleEditEmail: () => void;
}

const ConfirmNotValidatedEmailModal = ({
  show,
  toggleModal,
  handleSendContracts,
  handleEditEmail,
}: ConfirmNotValidatedEmailModalProps) => {
  return (
    <Modal show={show} onHide={toggleModal} centered>
      <Card className="modal-card">
        <CardHeader>
          <img src={ExclamationMark} alt="exclamation-mark"></img>
          <h1 className="not-validated-header">No fue posible validar el correo electrónico</h1>
        </CardHeader>
        <Card.Body>
          <Button className="primary-outlined-button w-100" onClick={handleSendContracts}>
            Continuar &gt;
          </Button>
          <Button className="primary-outlined-button w-100" onClick={handleEditEmail}>
            &lt; Editar correo electrónico
          </Button>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default ConfirmNotValidatedEmailModal;
