export enum TaxPayerTypeEnum {
  GRANDES_CONTRIBUYENTES = 1,
  NO_CEDE = 2,
  MONOTRIBUTO = 3,
  CEDE = 4,
  PEQUENA_EMPRESA = 5,
  MONOTRIBUTISTAS_MIDES = 6,
  ESTACION_SERVICIO = 7,
  FREE_SHOP = 8,
  AGENCIA_VIAJES = 9,
  INMOBILIARIAS = 10,
  ORGANISMOS_PUBLICOS = 11,
  COMERCIO_SIN_RETENCION_DGI = 12,
  EXENTO = 13,
  REDES_COBRANZA = 14,
  SERVICIOS_GASTRONOMICOS_FIESTAS_RG = 15,
  SERVICIOS_GASTRONOMICOS_FIESTAS_PE = 16,
}

export enum TaxPayerTypeDescriptionsEnum {
  MONOTRIBUTO_MIDES = 'Monotributo - Mides',
  MONOTRIBUTO = 'Monotributo',
  PEQUEÑA_EMPRESA = 'Pequeña empresa',
}
