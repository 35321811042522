import axios from './axios';

export async function login(email: string, password: string) {
  try {
    const res = await axios.post('/auth/login', { email, password });
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function logout() {
  try {
    const res = await axios.post('/auth/logout');
    return res;
  } catch (err: any) {
    return err.response;
  }
}

export async function verifyEmailToken(token: string) {
  try {
    const res = await axios.get(`/executive/active/${token}`);
    return res.data;
  } catch (err: any) {
    return err.response.data;
  }
}

export async function activateUser(
  token: string,
  document: number,
  password: string,
  confirmPassword: string,
) {
  const res = await axios.post(`/executive/active/${token}`, { document, password, confirmPassword });
  return res.data;
}

export async function resetPassword(email: string) {
  try {
    const res = await axios.post('/auth/forgot-password', { email });
    return res.data;
  } catch (err: any) {
    return err.response.data;
  }
}

export async function restorePassword(
  password: string,
  confirmPassword: string,
  document: number,
  token: string,
) {
  try {
    const res = await axios.post('/auth/restore-password', {
      password,
      confirmPassword,
      document,
      token,
    });

    return res.data;
  } catch (err: any) {
    return err.response.data;
  }
}
