import clsx from 'clsx';
import Lottie from 'lottie-react';
import { Button, Container, Row } from 'react-bootstrap';
import loadingAnimation from '../../assets/animations/loading.json';

interface NavigationFooterProps {
  nextHandler: any;
  nextText?: string;
  previousHandler?: any;
  previousText?: any;
  loading?: boolean;
  showPreviousIcon?: boolean;
}

export default function NavigationFooter({
  previousHandler,
  nextHandler,
  nextText,
  previousText,
  loading = false,
  showPreviousIcon = true,
}: NavigationFooterProps) {
  const nextButtonInnerContent = loading ? (
    <Lottie animationData={loadingAnimation} loop={true} style={{ height: 30, width: 30, margin: 'auto' }} />
  ) : nextText ? (
    `${nextText} >`
  ) : (
    'Continuar >'
  );

  return (
    <div className="navigation-footer col-12 container">
      <Container>
        <Row className={clsx('d-flex justify-content-center', previousHandler && 'row-with-previous')}>
          {previousHandler && (
            <Button
              onClick={previousHandler}
              variant="outline-danger"
              className="primary-outlined-button col col-md-2"
            >
              {showPreviousIcon && "<"}  {previousText || 'Volver'}
            </Button>
          )}
          <Button
            onClick={nextHandler}
            variant="outline-danger"
            className={clsx(
              'primary-outlined-button',
              previousHandler ? 'col-md-4' : 'col-6',
              loading && 'loading',
            )}
            disabled={!!loading}
          >
            {nextButtonInnerContent}
          </Button>
        </Row>
      </Container>
    </div>
  );
}
