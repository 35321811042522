export enum TerminalTypesEnum {
  NAD = 1,
  POS2000,
  SCANNTECH,
  RESONANCE,
  HANDY,
  SISTECO,
  GEOCOM,
  PRANASYS,
  FISERV,
  OCA,
  VISANET,
}
