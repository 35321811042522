import { useEffect, useState } from 'react';
import { getBusinessPlansTableData } from '../../services/commerce.service';
import {
  masterPersonalizedComisionHeadings,
  standarComisionHeadings,
  visaPersonalizedComisionHeadings,
} from '../../utils/data';
import {
  formatTableDataForTariffsMaster,
  formatTableDataForTariffsVisa,
  getEnumTypeValue,
} from '../../utils/helpers';
import CustomTable from '../CustomTable/CustomTable';

interface TariffBlockProps {
  isStandarTariff?: boolean;
  customTariff?: any;
  businessTaxPayerType: any;
  businessCategory: any;
  acceptVisa: boolean;
  acceptMaster: boolean;
}

function TariffBlock({
  isStandarTariff,
  customTariff,
  businessTaxPayerType,
  businessCategory,
  acceptVisa,
  acceptMaster,
}: TariffBlockProps) {
  const [tableDataVisa, setTableDataVisa] = useState<any[]>([]);
  const [tableDataMaster, setTableDataMaster] = useState<any[]>([]);

  useEffect(() => {
    const getTableData = async () => {
      const response = await getBusinessPlansTableData(
        getEnumTypeValue('taxPayer', businessTaxPayerType),
        getEnumTypeValue('businessCategory', businessCategory),
      );

      if (response.result) {
        const formattedTableDataVisa = formatTableDataForTariffsVisa(response.data);
        setTableDataVisa(formattedTableDataVisa);

        const formattedTableDataMaster = formatTableDataForTariffsMaster(response.data);
        setTableDataMaster(formattedTableDataMaster);
      }
    };

    getTableData();
  }, []);

  const masterData = [
    {
      rows: [
        { plan: 'DÉBITO', currency: `${customTariff?.masterDebit}%`, fees: '1,12%', deferred: '0,99%' },
        { plan: 'PREPAGO', currency: `${customTariff?.masterPrepaid}%`, fees: '1,10%', deferred: '1,04%' },
        {
          plan: 'CRÉDITO CONTADO',
          currency: `${customTariff?.masterCashCredit}%`,
          fees: '3,15%',
          deferred: '2,96%',
        },
        {
          plan: 'CRÉDITO CUOTAS',
          currency: `${customTariff?.masterInstallmentCredit}%`,
          fees: '4,44%',
          deferred: '4,17%',
        },
        {
          plan: 'Extranjeros DÉBITO',
          currency: `${customTariff?.masterForeignDebit}%`,
          fees: '3,40%',
          deferred: '3,40%',
        },
        {
          plan: 'Extranjeros CRÉDITO',
          currency: `${customTariff?.masterForeignCredit}%`,
          fees: '3,40%',
          deferred: '3,40%',
        },
      ],
    },
  ];

  const visaData = [
    {
      rows: [
        { plan: 'DÉBITO', currency: `${customTariff?.visaDebit}%`, fees: '1,12%', deferred: '0,99%' },
        { plan: 'PREPAGO', currency: `${customTariff?.visaPrepaid}%`, fees: '1,10%', deferred: '1,04%' },
        {
          plan: 'CRÉDITO CONTADO',
          currency: `${customTariff?.visaCashCredit}%`,
          fees: '3,15%',
          deferred: '2,96%',
        },
        {
          plan: 'CRÉDITO CUOTAS',
          currency: `${customTariff?.visaInstallmentCredit}%`,
          fees: '4,44%',
          deferred: '4,17%',
        },
        {
          plan: 'Extranjeros DÉBITO',
          currency: `${customTariff?.visaForeignDebit}%`,
          fees: '3,40%',
          deferred: '3,40%',
        },
        {
          plan: 'Extranjeros CRÉDITO',
          currency: `${customTariff?.visaForeignCredit}%`,
          fees: '3,40%',
          deferred: '3,40%',
        },
      ],
    },
  ];

  if (!isStandarTariff && !customTariff) return null;

  return (
    <div className="confirmation-card">
      <p className="confirmation-title">{'Planes Comerciales'.toUpperCase()}</p>
      {isStandarTariff ? (
        <>
          {acceptVisa && (
            <>
              <p className="processor-title">VISA</p>
              <CustomTable tableData={tableDataVisa} headings={standarComisionHeadings} />
            </>
          )}
          {acceptMaster && (
            <>
              <p className="processor-title">MASTER</p>
              <CustomTable tableData={tableDataMaster} headings={standarComisionHeadings} />
            </>
          )}
        </>
      ) : (
        <>
          <div className="table-wrapper">
            <CustomTable tableData={masterData} headings={masterPersonalizedComisionHeadings} />
            <CustomTable tableData={visaData} headings={visaPersonalizedComisionHeadings} />
          </div>
        </>
      )}
    </div>
  );
}

export default TariffBlock;
