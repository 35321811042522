import { Field, Form, Formik } from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import NewAgeDataIcon from '../../../assets/POS-activation.svg';
import POSIcon from '../../../assets/POS.svg';
import InfoBlock from '../../../components/BusinessInfo/InfoBlock';
import Input from '../../../components/Input/Input';
import RoundedCheckbox from '../../../components/Input/RadioButton';
import Select from '../../../components/Input/Select';
import Switch from '../../../components/Input/Switch';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import {
  getCities,
  getStates,
  getTerminals,
  getTerminalsPrice,
  saveTerminalInformation,
} from '../../../services/commerce.service';
import {
  BusinessTableColumn,
  BusinessTableRow,
  City,
  CustomTableChunk,
  Integration,
  State,
} from '../../../types/add_commerce.types';
import { TerminalDataRequest } from '../../../types/business/request';
import { ECommerceProcessorEnum } from '../../../types/enums/ecommerce-processor.enum';
import { BusinessPromotionEnum } from '../../../types/enums/promotion-enum';
import { ShippingTypeEnum } from '../../../types/enums/shipping-type.enum';
import { TaxPayerTypeDescriptionsEnum } from '../../../types/enums/taxpayer-type.enum';
import { BusinessTerminal } from '../../../types/terminals/model';
import {
  initialIntegratorsNotCurrentlyIncluded,
  integrationType,
  integrations,
  terminalHeadings,
} from '../../../utils/data';
import { capitalize, triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import schemas from '../../../utils/schemas';
import PersonalaizedTariffTable from '../Conditions/PersonalaizedTariff';

interface CheckboxOption {
  name: string;
  value: number;
}

let sameAsMainAddress: boolean = false;

function CommerceTerminals() {
  const location: any = useLocation();
  const navigate = useNavigate();
  const localState: any = location.state;
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [chosenState, setChosenState] = useState<State | null>(localState?.neighborhood?.city?.state || null);
  const [terminals, setTerminals] = useState<CustomTableChunk[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showNadProducts, setShowNadProducts] = useState<boolean>(true);

  const formRef = useRef<any>(null);

  const handleGoBack = () => navigate(pagesPaths.CommerceConditions, { state: { ...localState } });
  const handleSubmit = () => formRef.current.submitForm();

  const getTerminalFromValues = (values: any) => {
    if (values.terminals[0]?.rows) {
      const terminals = values.terminals[0].rows.filter(
        (terminal: BusinessTableRow) => Number(terminal.currency) > 0,
      );

      return terminals.map((terminal: BusinessTableRow) => {
        return { type: terminal.id, quantity: terminal.currency, UIPrice: +terminal.fees };
      });
    } else if (values.terminals) {
      return values.terminals.map((terminal: BusinessTerminal) => {
        return { type: terminal.type, quantity: +terminal.quantity, UIPrice: terminal.price };
      });
    }

    return [];
  };

  const saveTerminalsInformation = async (values: any) => {
    const integrationTypeValues = values.integrationType.filter((value: any) => value !== 0);
    const formattedTerminals = getTerminalFromValues(values);

    if (
      +values.integration === 0 &&
      values.commercialCenter === 'NO' &&
      values.posSale === 'SI' &&
      !formattedTerminals.length
    ) {
      triggerToasts('La cantidad de terminales debe ser al menos 1');
    } else if (noPosSaleComments.length > 512) {
      triggerToasts('Comentarios demasiado extensos');
    } else if (
      !values.ecommerce &&
      values.paymentProcessor === '' &&
      values.integration === '0' &&
      (+values.automaticDebit === 0 || !values.automaticDebit) &&
      values.posSale === 'NO' &&
      localState.nadOnboarding
    ) {
      triggerToasts('Debe elegir algún producto');
    } else {
      if (values.ecommerce && integrationTypeValues.length === 0 && !values.paymentProcessor) {
        return triggerToasts('Debe elegir alguna pasarela de pago o producto');
      }

      setIsLoading(true);

      const requestBody: TerminalDataRequest = {
        automaticDebit: +values.automaticDebit === 1,
        ecommerce: values.ecommerce || false,
        ecommerceProcessor: values?.paymentProcessor,
        ecommerceIntegrationType: integrationTypeValues,
        integration: values.integration,
        commercialCenter: values.commercialCenter,
        terminals: formattedTerminals,
        nadServicePayment: values.settlement,
        promotion: evaluateIfBusinessHasPromotion(values.promotion, values),
        shippingType: values.withdrawInNad ? '' : values.shippingType,
        shipping:
          +values.integration === 0 && values.commercialCenter === 'NO' && values.posSale === 'SI'
            ? !values.withdrawInNAD
            : false,
        businessId: localState.id,
        posSale: values.posSale,
        noPosSaleComments: noPosSaleComments,
        shippingAddress: {
          courier: values.withdrawInNad ? '' : values.courier,
          neighborhood: values.withdrawInNad ? '' : values.addressNeighborhood,
          addressStreet: values.withdrawInNad ? '' : values.addressStreet,
          addressNumber: values.withdrawInNad ? '' : values.addressNumber,
          addressFlatNumber: values.withdrawInNad ? '' : values.addressFlatNumber,
          addressSolar: values.withdrawInNad ? '' : values.addressSolar,
          addressPlot: values.withdrawInNad ? '' : values.addressPlot,
          schedule: values.withdrawInNad ? '' : values.deliveryTime,
          contactPhone: values.withdrawInNad ? '' : values.phoneNumber,
          city: values.withdrawInNad ? '' : values.addressCity,
        },
      };

      const response = await saveTerminalInformation(requestBody, localState.businessId || localState.id);
      setIsLoading(false);

      if (response.result && response.data) {
        return navigate(pagesPaths.CommerceCodes, {
          state: { ...localState, ...response.data.businessData },
        });
      }
    }
  };

  const fetchStates = async () => {
    const responseStates = await getStates();
    setStates(responseStates);
  };

  const fetchCities = async (state: State) => {
    const responseCities = await getCities(state.id);
    setCities(responseCities.data);
  };

  const fetchTerminals = async () => {
    const responseTerminals = await getTerminals();
    const tableFormatedTerminals: BusinessTableRow[] = responseTerminals.data.map((terminal: any) => {
      const preSettedTerminal = localState?.terminals?.length
        ? localState.terminals.find((businessTerminal: any) => +businessTerminal.type === terminal.id)
        : null;

      if (preSettedTerminal) {
        return {
          id: terminal.id.toString(),
          plan: terminal.name,
          currency: preSettedTerminal.quantity,
          fees: terminal.price,
          deferred: preSettedTerminal.price,
        };
      } else {
        return {
          id: terminal.id.toString(),
          plan: terminal.name,
          currency: 0,
          fees: terminal.price,
          deferred: 0,
        };
      }
    });

    const tableData: CustomTableChunk[] = [{ rows: tableFormatedTerminals }];
    setTerminals(tableData);
  };

  const handleOnChangeState = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('department', +targetValue);
    setFieldValue('addressCity', '');
    setFieldValue('addressNeighborhood', '');
    const chosenState = states.find((state: State) => state.id === +targetValue);

    setChosenState(chosenState || null);
  };

  const handleOnChangeCity = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('addressCity', +targetValue);
    setFieldValue('addressNeighborhood', '');
  };

  const handleChangePaymentProcessor = (value: string, setFieldValue: any) =>
    setFieldValue('paymentProcessor', value);

  const handleChangeShippingType = (value: string, setFieldValue: any) =>
    setFieldValue('shippingType', value);

  // TODO: provisional solution for coexistence of initial integrators and integrators modification requested
  const assignIntegration = () => {
    const initialIntegratorsNotCurrentlyIncludedValues = initialIntegratorsNotCurrentlyIncluded.map(
      (integrator) => integrator.value,
    );

    if (localState.integration) {
      if (initialIntegratorsNotCurrentlyIncludedValues.includes(localState.integration)) {
        return '106'; // "Otros"
      } else {
        return localState.integration;
      }
    } else {
      return '0';
    }
  };

  useEffect(() => {
    fetchStates();
    fetchCities(localState?.shippingAddress?.city?.state as State);
  }, [localState?.shippingAddress?.city?.state]);

  useEffect(() => {
    chosenState !== null && fetchCities(chosenState);
  }, [chosenState]);

  const handleCheckboxIntegrationType = (
    option: CheckboxOption,
    values: any,
    setFieldValue: (name: string, value: Array<number>) => void,
  ) => {
    if (values.integrationType.includes(option.value)) {
      setFieldValue(
        'integrationType',
        values.integrationType.filter((id: number) => id !== option.value),
      );
    } else {
      setFieldValue('integrationType', [...values.integrationType, option.value]);
    }
  };

  useEffect(() => {
    if (localState?.nadOnboarding === false) {
      setShowNadProducts(false);
    } else {
      fetchTerminals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePersonalaizedTable = async (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
    column: BusinessTableColumn,
  ) => {
    const newTableData = [...tableData];
    const { value } = event.target;
    const id = event.target.id.split('_')[1];
    const indexInRows = newTableData[0].rows.findIndex((row) => row?.id?.toString() === id);

    if (value) {
      const newTerminalsValues = await getTerminalsPrice(id, value);
      newTableData[0].rows[indexInRows].deferred = newTerminalsValues.data.price;
    }

    newTableData[0].rows[indexInRows][column] = value;
    setFieldValue(field, newTableData);
  };

  const handleFillWithMainAddressData = async (
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    sameAsMainAddress = !sameAsMainAddress;

    handleOnChangeState(sameAsMainAddress ? String(localState?.city?.stateId!) : '', setFieldValue);
    handleOnChangeCity(sameAsMainAddress ? String(localState.city?.id!) : '', setFieldValue);
    setFieldValue('addressStreet', sameAsMainAddress ? localState.addressStreet : '');
    setFieldValue('addressNumber', sameAsMainAddress ? localState.addressNumber! : '');
    setFieldValue('addressFlatNumber', sameAsMainAddress ? localState.addressFlatNumber : '');
    setFieldValue('addressSolar', sameAsMainAddress ? localState.addressSolar : '');
    setFieldValue('addressPlot', sameAsMainAddress ? localState.addressPlot : '');
  };

  const getIntegrationTypesValues = (integrationTypes: any[]): number[] => {
    const integrationTypeValues = integrationTypes.map((integrationType: any) => {
      return integrationType.id;
    });

    return integrationTypeValues;
  };

  const [noPosSaleComments, setNoPosSaleComments] = useState<string>(localState.noPosSaleComments || '');

  const handleTextEditor = (event: string) => {
    setNoPosSaleComments(event);
  };

  const getBusinessPromotion = (localState: any): string => {
    const allowedTaxpayersForDmrPromotion = [
      TaxPayerTypeDescriptionsEnum.MONOTRIBUTO,
      TaxPayerTypeDescriptionsEnum.MONOTRIBUTO_MIDES,
      TaxPayerTypeDescriptionsEnum.PEQUEÑA_EMPRESA,
    ];

    if (localState.nadOnboarding) {
      if (allowedTaxpayersForDmrPromotion.includes(localState?.taxpayerType?.description)) {
        return BusinessPromotionEnum.PROMO_12_MESES_DMR;
      } else {
        return BusinessPromotionEnum.PROMO_6_MESES;
      }
    } else {
      return BusinessPromotionEnum.PROMO_2_MESES;
    }
  };

  const evaluateIfBusinessHasPromotion = (promotion: string, values: any): string => {
    if (values.posSale === 'SI' && values.commercialCenter === 'NO' && !+values.integration) {
      return promotion;
    }

    return '';
  };

  return (
    <>
      <Container className="commerce-terminals">
        <ProgressBar now={(8 / 12) * 100} />
        <Row>
          <Col lg={12}>
            <h1 className="title">
              <img src={POSIcon} alt="POS" className="me-3" />
              Productos
            </h1>
            <Formik
              initialValues={{
                terminalNetwork: '',
                automaticDebit:
                  typeof localState.automaticDebit === 'boolean'
                    ? localState.automaticDebit
                      ? 1
                      : 0
                    : undefined,
                ecommerce: localState.commercialCenter ? localState.ecommerce : undefined,
                paymentProcessor: localState?.ecommerce?.processor || '',
                integration: assignIntegration(),
                integrationType: localState?.ecommerce?.integrationTypes?.length
                  ? getIntegrationTypesValues(localState?.ecommerce?.integrationTypes)
                  : [0],
                commercialCenter: localState.commercialCenter || 'NO',
                posSale: !localState.posSale ? 'NO' : 'SI',
                noPosSaleComments: localState.noPosSaleComments || '',
                terminals: localState?.terminals || '',
                settlement: true,
                promotion: getBusinessPromotion(localState),
                shippingType: localState.shippingType || ShippingTypeEnum.NAD,
                withdrawInNAD: localState.commercialCenter ? !localState.shipping : false,
                directionSameAsResidence: false,
                courier: localState?.shippingAddress?.courier || '',
                deliveryTime: localState?.shippingAddress?.schedule || '',
                phoneNumber: localState?.shippingAddress?.contactPhone || '',
                addressStreet: localState?.shippingAddress?.addressStreet || '',
                addressNumber: localState?.shippingAddress?.addressNumber || '',
                department: localState?.shippingAddress?.city?.state?.id || '',
                addressCity: localState?.shippingAddress?.city?.id || '',
                addressFlatNumber: localState?.shippingAddress?.addressFlatNumber || '',
                addressSolar: localState?.shippingAddress?.addressSolar || '',
                addressPlot: localState?.shippingAddress?.addressPlot || '',
              }}
              onSubmit={(values) => saveTerminalsInformation(values)}
              innerRef={(formElement: any) => (formRef.current = formElement)}
              validationSchema={schemas.CommerceTerminalsSchema}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <Select
                    className="col-md-12 mt-4"
                    label="¿El comercio trabaja con sistema PAR?"
                    name="automaticDebit"
                    required
                  >
                    <option key="yes" value={1}>
                      Si
                    </option>
                    <option key="no" value={0}>
                      No
                    </option>
                  </Select>
                  <Switch
                    htmlFor="ecommerce"
                    id="ecommerce_switch"
                    label="Agregar e-commerce al comercio"
                    handleOnClick={() => setFieldValue('ecommerce', !values.ecommerce)}
                    name="ecommerce"
                    containerClass="col-md-12 mt-0 px-0"
                    checked={values.ecommerce}
                  />
                  {values.ecommerce && (
                    <>
                      <div className="col-md-4">
                        <p className="subtitle mb-2">¿Tienes pasarela de pago?</p>
                        <div className="col-md-12 mt-1">
                          <RoundedCheckbox
                            name="paymentProcessor"
                            label={capitalize(ECommerceProcessorEnum.PLEXO)}
                            onChange={handleChangePaymentProcessor}
                            setFieldValue={setFieldValue}
                            value={ECommerceProcessorEnum.PLEXO}
                            customClass="px-0"
                            currentComboCheckboxValue={values.paymentProcessor}
                          />
                          <RoundedCheckbox
                            name="paymentProcessor"
                            label={capitalize(ECommerceProcessorEnum.BAMBOO)}
                            onChange={handleChangePaymentProcessor}
                            setFieldValue={setFieldValue}
                            value={ECommerceProcessorEnum.BAMBOO}
                            currentComboCheckboxValue={values.paymentProcessor}
                          />
                          <RoundedCheckbox
                            name="paymentProcessor"
                            label={capitalize(ECommerceProcessorEnum.OTRO)}
                            onChange={handleChangePaymentProcessor}
                            setFieldValue={setFieldValue}
                            value={ECommerceProcessorEnum.OTRO}
                            currentComboCheckboxValue={values.paymentProcessor}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 d-flex flex-column mt-4">
                        <p className="subtitle mb-2">¿Va a adquirir alguno de los siguientes productos?</p>
                        <div className="col-md-8 d-flex">
                          {integrationType.map((option: CheckboxOption, index: number) => (
                            <label
                              key={`${option.name}_${index}`}
                              className="checkbox-label col-3 d-flex align-items-center mt-1"
                              htmlFor={option.name}
                            >
                              <Field
                                className="checkbox"
                                type="checkbox"
                                name="integrationType"
                                id={option.name}
                                checked={values?.integrationType?.includes(option.value)}
                                onChange={() => handleCheckboxIntegrationType(option, values, setFieldValue)}
                              />
                              {option.name}
                            </label>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  {showNadProducts && (
                    <>
                      <Row className="mt-4">
                        {(values.integration !== '0' || values.commercialCenter !== 'NO') && (
                          <label className="checkbox-label col-12 mt-3 mb-4">
                            Esta gestión está siendo llevada a cabo por un ejecutivo de New Age Data
                          </label>
                        )}
                        <Select
                          label="¿El comercio cuenta con facturación electrónica?"
                          className="col-md-6"
                          name="integration"
                          required
                          noPlaceholder
                        >
                          {integrations.map((integration: Integration, index: number) => (
                            <option key={`${integration.value}_${index}`} value={integration.value}>
                              {integration.label}
                            </option>
                          ))}
                        </Select>
                        <Select
                          label="Centro comercial"
                          className="col-md-6"
                          name="commercialCenter"
                          required
                          noPlaceholder
                        >
                          <option key={1} value="NO">
                            No
                          </option>
                          <option key={2} value="Centro Comercial Salto">
                            Centro Comercial Salto
                          </option>
                          <option key={3} value="Centro Comercial Paysandú">
                            Centro Comercial Paysandú
                          </option>
                        </Select>
                        <Select label="¿Venta con POS?" className="col-md-6" name="posSale" required>
                          <option key={1} value="SI">
                            Si
                          </option>
                          <option key={2} value="NO">
                            No
                          </option>
                        </Select>
                      </Row>
                      {+values.integration === 0 &&
                      values.commercialCenter === 'NO' &&
                      values.posSale === 'SI' ? (
                        <>
                          <h1 className="title">
                            <img alt="Terminal de New Age Data" src={NewAgeDataIcon} className="me-3" />
                            Nueva terminal de New Age Data
                          </h1>
                          <PersonalaizedTariffTable
                            setFieldValue={setFieldValue}
                            headings={terminalHeadings}
                            tableData={terminals}
                            field="terminals"
                            handleChangePersonalaizedTable={handleChangePersonalaizedTable}
                          />
                          {values.posSale === 'SI' && (
                            <>
                              <h3 className="section-title mt-4">COBRO DE SERVICIOS NAD</h3>
                              <label className="checkbox-label col-12 mt-3 mb-5 bordered-text">
                                Las terminales POS se debitarán en la liquidación de Getnet
                              </label>
                            </>
                          )}
                          <Select
                            label="Seleccionar promoción"
                            name="promotion"
                            className="col-md-12"
                            required
                            disabled
                          >
                            <option key={1} value="NO">
                              No
                            </option>
                            <option key={2} value="PROMO6MESES">
                              6 meses gratis para Clientes nuevos.
                            </option>
                            <option key={3} value="PROMO2MESES">
                              2 meses gratis para ya Clientes
                            </option>
                            <option key={4} value="PROMO12MESESDMR">
                              Promo - Literal E - Monotributo - Monotributo Mides
                            </option>
                          </Select>

                          <h3 className="section-title mt-4">FORMA DE ENTREGA</h3>
                          <div className="col-md-4">
                            <RoundedCheckbox
                              name="shippingType"
                              label="NAD"
                              onChange={handleChangeShippingType}
                              setFieldValue={setFieldValue}
                              value={ShippingTypeEnum.NAD}
                              customClass="px-0"
                              currentComboCheckboxValue={values.shippingType}
                            />
                            <RoundedCheckbox
                              name="shippingType"
                              label="Canal de ventas"
                              onChange={handleChangeShippingType}
                              setFieldValue={setFieldValue}
                              value={ShippingTypeEnum.SALES_CHANNEL}
                              currentComboCheckboxValue={values.shippingType}
                            />
                          </div>
                          {ShippingTypeEnum.NAD === values.shippingType && (
                            <>
                              <Switch
                                htmlFor="withdrawInNAD"
                                id="withdrawInNAD_switch"
                                label="Retirar producto en New Age Data"
                                handleOnClick={() => setFieldValue('withdrawInNAD', !values.withdrawInNAD)}
                                name="withdrawInNAD"
                                containerClass="col-md-12 px-0"
                                checked={values.withdrawInNAD}
                              />
                              {values.withdrawInNAD ? (
                                <>
                                  <InfoBlock
                                    title="OFICINAS DE NEW AGE DATA"
                                    fields={[
                                      { name: 'Dirección', value: 'Buenos Aires 579' },
                                      { name: 'Horarios', value: '9:00 a 18:00hs' },
                                    ]}
                                  />
                                </>
                              ) : (
                                <Row>
                                  <Select name="courier" label="Selector de correo" required>
                                    <option key={1} value="PedidosYa">
                                      Pedidos Ya
                                    </option>
                                    <option key={2} value="DAC">
                                      DAC
                                    </option>
                                    <option key={3} value="Districar">
                                      Districar
                                    </option>
                                    <option key={4} value="DePunta">
                                      DePunta
                                    </option>
                                  </Select>
                                  <div className="input col-md-12 checkbox-label form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="sameAsBusinessInfo"
                                      id="flexSwitchCheckDefault"
                                      onChange={() => {
                                        handleFillWithMainAddressData(setFieldValue);
                                      }}
                                    />
                                    <label htmlFor="flexSwitchCheckDefault">
                                      Dirección igual al Domicilio Principal
                                    </label>
                                  </div>
                                  <Row>
                                    <Select
                                      className="col-md-6"
                                      label="Departamento"
                                      required
                                      name="department"
                                      handleOnChange={handleOnChangeState}
                                      disabled={sameAsMainAddress}
                                    >
                                      {states.map((state: State, index: number) => (
                                        <option key={index} value={state.id}>
                                          {state.name}
                                        </option>
                                      ))}
                                    </Select>
                                    <Select
                                      className="col-md-6"
                                      name="addressCity"
                                      handleOnChange={handleOnChangeCity}
                                      label="Localidad"
                                      required
                                      disabled={sameAsMainAddress}
                                    >
                                      {cities.map((city: City, index: number) => (
                                        <option key={index} value={city.id}>
                                          {city.name}
                                        </option>
                                      ))}
                                    </Select>
                                    <Input
                                      className="input col-12"
                                      type="text"
                                      name="addressStreet"
                                      label="Calle"
                                      disabled={sameAsMainAddress}
                                      required={!values.directionSameAsResidence || !values.withdrawInNAD}
                                    />
                                    <Input
                                      className="input col-md-3"
                                      type="text"
                                      name="addressNumber"
                                      label="N° de puerta"
                                      disabled={sameAsMainAddress}
                                      required={!values.directionSameAsResidence || !values.withdrawInNAD}
                                    />
                                    <Input
                                      className="input col-md-3"
                                      type="number"
                                      name="addressFlatNumber"
                                      label="Apto (Opcional)"
                                      disabled={sameAsMainAddress}
                                    />
                                    <Input
                                      className="input col-md-3"
                                      type="number"
                                      name="addressSolar"
                                      label="Solar (Opcional)"
                                      disabled={sameAsMainAddress}
                                    />
                                    <Input
                                      className="input col-md-3"
                                      type="number"
                                      name="addressPlot"
                                      disabled={sameAsMainAddress}
                                      label="Manzana (Opcional)"
                                    />
                                    <Select
                                      className="col-md-6 mt-6"
                                      label="Horario"
                                      name="deliveryTime"
                                      required
                                    >
                                      <option key={0} value="10-18hs">
                                        10 a 18 hs
                                      </option>
                                      <option key={1} value="10-12hs">
                                        10 a 12 hs
                                      </option>
                                      <option key={2} value="15-18hs">
                                        15 a 18 hs
                                      </option>
                                    </Select>
                                    <Input
                                      className="input col-md-6"
                                      type="tel"
                                      name="phoneNumber"
                                      label="Télefono de contacto"
                                      required={values.shippingType === ShippingTypeEnum.NAD}
                                    />
                                  </Row>
                                </Row>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <label className="label-text-editor">Comentarios</label>
                          <ReactQuill
                            theme="snow"
                            value={noPosSaleComments}
                            onChange={(event) => handleTextEditor(event)}
                          />
                        </>
                      )}
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={isLoading} />
    </>
  );
}
export default CommerceTerminals;
