interface SwitchProps {
  handleOnClick: () => void;
  name: string;
  id: string;
  htmlFor: string;
  label: string;
  checked: boolean;
  containerClass?: string;
}

function Switch({ handleOnClick, name, id, htmlFor, label, checked, containerClass }: SwitchProps) {
  return (
    <div className={`input checkbox-label form-check form-switch ${containerClass}`}>
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        name={name}
        id={id}
        onChange={handleOnClick}
        checked={checked}
      />
      <label htmlFor={htmlFor}>{label}</label>
    </div>
  );
}

export default Switch;
