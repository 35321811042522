import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Input/Select';
import { getBusinessCategories, getCities, getStates } from '../../../services/commerce.service';
import {
  BusinessCategory,
  City,
  CommerceAddressState,
  Neighborhood,
  NewBranchValuess,
  State,
} from '../../../types/add_commerce.types';
import { sortArrayAlphabetically } from '../../../utils/helpers';
import schemas from '../../../utils/schemas';

interface NewBranchProps {
  innerRef: any;
  submitBranch(
    values: NewBranchValuess,
    index: number,
    businessCategoryId: string | undefined,
    state: State | undefined,
    city: City | undefined,
    neighborhood: Neighborhood | undefined,
  ): void;
  index: number;
  branchData: NewBranchValuess;
  handleDelete: (index: number) => void;
}

let sameAsBusinessInfo: boolean = false;

function NewBranch({ innerRef, submitBranch, index, branchData, handleDelete }: NewBranchProps) {
  const location = useLocation();
  const localState = location.state as CommerceAddressState;

  const [businessCategories, setBusinessCategories] = useState<Array<BusinessCategory>>([]);
  const [states, setStates] = useState<Array<State>>([]);
  const [cities, setCities] = useState<Array<City>>([]);
  const [chosenState, setChosenState] = useState<State | undefined>(branchData?.city?.state);
  const [chosenCity, setChosenCity] = useState<City | undefined>(branchData?.city);
  const [chosenNeighborhood, setChosenNeighborhood] = useState<Neighborhood | undefined>(
    branchData?.neighborhood,
  );
  const [chosenCategory, setChosenCategory] = useState<BusinessCategory | undefined>(undefined);

  const fetchBusinessCategories = async () => {
    const responseBusinessCategories = await getBusinessCategories();
    if (Array.isArray(responseBusinessCategories.data)) {
      const sortedResponse = sortArrayAlphabetically(responseBusinessCategories.data, 'name');
      setBusinessCategories(sortedResponse);
    }
  };

  const fetchStates = async () => {
    const responseStates = await getStates();
    setStates(responseStates);
  };

  const fetchCities = async (state: State | undefined) => {
    if (state) {
      const responseCities = await getCities(state.id);
      return setCities(responseCities.data);
    }
  };

  const handleOnChangeBusinessCategory = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('businessCategoryId', +targetValue);
    const chosenBusinessCategory = businessCategories.find(
      (businessCategory: BusinessCategory) => businessCategory?.id === +targetValue,
    );
    setChosenCategory(chosenBusinessCategory);
  };

  const handleOnChangeState = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('state', +targetValue);
    setFieldValue('city', '');
    setFieldValue('neighborhood', '');
    const chosenState = states.find((state: State) => state?.id === +targetValue);
    setChosenState(chosenState || undefined);
    setChosenCity(undefined);
    setChosenNeighborhood(undefined);
  };

  const handleOnChangeCity = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('city', +targetValue);
    setFieldValue('neighborhood', '');
    const chosenCity = cities.find((city: City) => city?.id === +targetValue);
    setChosenCity(chosenCity || undefined);
    setChosenNeighborhood(undefined);
  };

  useEffect(() => {
    fetchBusinessCategories();
  }, []);

  useEffect(() => {
    fetchStates();
    fetchCities(chosenState);
    branchData?.city?.state && fetchCities(branchData?.city?.state as State);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData?.state, branchData?.city]);

  useEffect(() => {
    chosenState && fetchCities(chosenState);
  }, [chosenState]);

  const handleFillWithBranchData = async (setFieldValue: (name: string, value: string | number) => void) => {
    sameAsBusinessInfo = !sameAsBusinessInfo;

    handleOnChangeState(sameAsBusinessInfo ? String(localState?.city?.stateId!) : '', setFieldValue);
    handleOnChangeCity(sameAsBusinessInfo ? String(localState.city?.id!) : '', setFieldValue);
    setFieldValue('addressStreet', sameAsBusinessInfo ? localState.addressStreet : '');
    setFieldValue('addressNumber', sameAsBusinessInfo ? localState.addressNumber! : '');
    setFieldValue('addressFlatNumber', sameAsBusinessInfo ? localState.addressFlatNumber : '');
    setFieldValue('addressSolar', sameAsBusinessInfo ? localState.addressSolar : '');
    setFieldValue('addressPlot', sameAsBusinessInfo ? localState.addressPlot : '');
  };

  const getSchema = () => {
    if (sameAsBusinessInfo) {
      return schemas.ActivateCommerceSecondStepSameAddress;
    } else {
      return schemas.ActivateCommerceSecondStep;
    }
  };

  return (
    <Formik
      innerRef={innerRef}
      initialValues={{
        fantasyName: branchData?.fantasyName || '',
        businessCategoryId: branchData?.businessCategory?.id.toString() || '',
        state: branchData?.city?.state!,
        city: branchData?.city!,
        neighborhood: branchData?.neighborhood!,
        addressStreet: branchData?.addressStreet!,
        addressNumber: branchData?.addressNumber!,
        addressFlatNumber: branchData?.addressFlatNumber!,
        addressSolar: branchData?.addressSolar!,
        addressPlot: branchData?.addressPlot!,
      }}
      validationSchema={getSchema}
      onSubmit={(values: NewBranchValuess) =>
        submitBranch(
          values,
          index,
          chosenCategory?.id.toString(),
          chosenState,
          values.city,
          chosenNeighborhood,
        )
      }
      validateOnMount
    >
      {({ values, setFieldValue }) => (
        <Form className="form-row">
          <div className="title-row">
            <p className="input-subtitle">SUCURSAL {index + 1}</p>
            {index > 0 && (
              <FontAwesomeIcon
                className="faTrashCan"
                icon={faTrashCan as IconProp}
                onClick={() => handleDelete(index)}
              />
            )}
          </div>
          <Row>
            <Input
              className={clsx('input', index !== 0 && 'col-md-6')}
              type="text"
              name="fantasyName"
              label="Nombre Fantasía"
              required
              index={index}
            />
            {index !== 0 && (
              <Select
                name="businessCategoryId"
                handleOnChange={handleOnChangeBusinessCategory}
                className="col-md-6"
                label="Sub rubro"
                index={index}
              >
                {businessCategories?.map((category: BusinessCategory, index: number) => (
                  <option value={category?.id} key={`${category?.id}_${index}`}>
                    {category?.name}
                  </option>
                ))}
              </Select>
            )}
            {index === 0 && (
              <div className="input col-md-12 checkbox-label form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="sameAsBusinessInfo"
                  id="flexSwitchCheckDefault"
                  onClick={() => handleFillWithBranchData(setFieldValue)}
                />
                <label htmlFor="flexSwitchCheckDefault">Dirección igual al Domicilio Principal</label>
              </div>
            )}
            <Select
              name="state"
              handleOnChange={handleOnChangeState}
              className="col-md-6"
              label="Departamento"
              required
              disabled={sameAsBusinessInfo && index === 0}
              index={index}
            >
              {states.map((state: State, index: number) => (
                <option value={state?.id} key={`${state?.id}_${index}`}>
                  {state?.name}
                </option>
              ))}
            </Select>
            <Select
              name="city"
              handleOnChange={handleOnChangeCity}
              className="col-md-6"
              label="Localidad"
              required
              disabled={sameAsBusinessInfo && index === 0}
              index={index}
            >
              {cities.map((city: City, index: number) => (
                <option value={city?.id} key={`${city?.id}_${index}`}>
                  {city?.name}
                </option>
              ))}
            </Select>
            <Input
              className="input col-12"
              type="text"
              name="addressStreet"
              label="Calle"
              required
              readOnly={sameAsBusinessInfo && index === 0}
              index={index}
            />
            <Input
              className="input col-md-3"
              type="text"
              name="addressNumber"
              label="Nº de puerta"
              required
              readOnly={sameAsBusinessInfo && index === 0}
              index={index}
            />
            <Input
              className="input col-md-3"
              type="number"
              name="addressFlatNumber"
              label="Apto (opcional)"
              readOnly={sameAsBusinessInfo && index === 0}
              index={index}
            />
            <Input
              className="input col-md-3"
              type="number"
              name="addressSolar"
              label="Solar (opcional)"
              readOnly={sameAsBusinessInfo && index === 0}
              index={index}
            />
            <Input
              className="input col-md-3"
              type="number"
              name="addressPlot"
              label="Manzana (opcional)"
              readOnly={sameAsBusinessInfo && index === 0}
              index={index}
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default NewBranch;
