import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getBusinessPlansTableData } from '../../services/commerce.service';
import {
  AddCommerceSixthAndFinalStepLocationState,
  BusinessTableChunk,
  Contract,
  FinalStepAttachments,
  InfoBlockField,
  NewBeneficiaryValues,
  NewBranchValues,
} from '../../types/add_commerce.types';
import { FormStatusEnum } from '../../types/enums/form-status.enum';
import {
  bankAccountFields,
  banks,
  basicInfoFields,
  economicActivityFields,
  laws,
  mainAddressFields,
  tributaryInfoFields,
} from '../../utils/data';
import {
  formatTableData,
  getBeneficiaries,
  getEnumName,
  getEnumTypeValue,
  isEmptyField,
  updateBlockFields,
  updateDuplicatedBlockFields,
} from '../../utils/helpers';
import Attachments from './Attachments';
import ECommerceBlock from './ECommerceBlock';
import InfoBlock from './InfoBlock';
import ShareBlock from './ShareBlock';
import TariffBlock from './TariffBlock';

interface BusinessInfoProps {
  businessData: AddCommerceSixthAndFinalStepLocationState;
  showAttachments: boolean;
  setCheckboxState?: Dispatch<SetStateAction<boolean>>;
  checkboxState?: boolean;
  setAnticipatePayments?: Dispatch<SetStateAction<boolean>>;
  ecommerceAndTariffsForm?: any;
  anticipatePayments?: boolean;
  comment?: string;
  commerceId?: any;
  status?: FormStatusEnum | null;
  comesFromSearchCommerce?: boolean;
  handleStatus?: () => void;
  uploadedContract?: boolean;
  setIsLoading?: any;
}

function BusinessInfo({
  businessData,
  showAttachments = false,
  setCheckboxState,
  checkboxState,
  setAnticipatePayments,
  anticipatePayments,
  comment,
  commerceId,
  status = null,
  comesFromSearchCommerce = false,
  ecommerceAndTariffsForm,
  handleStatus,
  uploadedContract,
  setIsLoading,
}: BusinessInfoProps) {
  const [tableData, setTableData] = useState<Array<BusinessTableChunk>>([]);
  const [tableDataError, setTableDataError] = useState<string>('');

  const getTableData = async () => {
    const response = await getBusinessPlansTableData(
      getEnumTypeValue('taxPayer', businessData.taxpayerType!),
      getEnumTypeValue('businessCategory', businessData.businessCategory!),
    );

    if (response.result) {
      const formattedTableData = formatTableData(response.data);
      setTableData(formattedTableData);
    } else {
      setTableDataError(response?.data?.showMessage?.ES);
    }
  };

  useEffect(() => {
    if (!comesFromSearchCommerce) {
      getTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const beneficiaries = getBeneficiaries(businessData?.branches);

  const attachments: FinalStepAttachments = {
    cardFormDGI: businessData?.cardFormDGI! as string,
    beneficiaries,
    extraDocuments: [
      businessData?.extraDocumentOne as string,
      businessData?.extraDocumentTwo as string,
      businessData?.extraDocumentThree as string,
    ],
  };

  const basicInfoValues = [
    businessData?.rut,
    businessData?.fantasyName,
    businessData?.socialReason,
    `${businessData?.contactFirstName} ${businessData?.contactLastName}`,
    businessData?.phone,
    businessData?.cellphone,
    businessData?.email,
    businessData?.averageMonthlyBilling,
  ];

  const mainAddressValues = [
    businessData?.city?.state?.name,
    businessData?.city?.name,
    `${businessData?.addressStreet} ${businessData?.addressNumber}`,
    isEmptyField(businessData?.addressFlatNumber),
    isEmptyField(businessData?.addressSolar),
    isEmptyField(businessData?.addressPlot),
    businessData?.averageMonthlyBilling,
  ];

  const bankAccountValues = [
    banks.find((bank) => bank.id === businessData?.bankExternalId)?.name,
    businessData?.accountNumber,
    getEnumName('currency', String(businessData?.accountCurrency)),
    businessData?.accountBranch,
    businessData?.accountHolder,
  ];

  const lawValues = [
    businessData?.law_19210,
    businessData?.law_17934,
    businessData?.law_18083,
    businessData?.law_18910,
  ];

  const basicInfoUpdated = updateBlockFields(basicInfoFields, basicInfoValues);
  const bankAccountUpdatedFields = updateBlockFields(bankAccountFields, bankAccountValues);
  const mainAddressUpdated = updateBlockFields(mainAddressFields, mainAddressValues);
  const tributaryInfoUpdated = updateBlockFields(
    tributaryInfoFields,
    businessData?.taxpayerType?.description,
  );
  const economicActivityUpdated = updateBlockFields(
    economicActivityFields,
    businessData?.businessCategory?.name,
  );

  const lawsUpdated: InfoBlockField[] = updateBlockFields(
    laws.map((law) => law.name),
    lawValues,
  );

  const terminalValues = [
    businessData?.terminals?.find((t) => t.type === '4')?.quantity || '-',
    businessData?.terminals?.find((t) => t.type === '3')?.quantity || '-',
  ];
  const terminalsUpdated: InfoBlockField[] = updateBlockFields(['3Gs', 'Wifi'], terminalValues);

  const contractsUpdated = updateBlockFields(['Contratos enviados a firmar', ''], ['Getnet', 'NAD']); // TODO: check with backend

  const contractsSigned: boolean | undefined = businessData?.contracts?.every(
    (contract: Contract) => contract.vaFirmaStatus === 'SIGNED' || contract.vaFirmaStatus === 'NOT_SENDED',
  );

  const showECommerceBlock: boolean = !!(
    contractsSigned &&
    !businessData?.hasEcommerce &&
    businessData?.principalEmail
  );

  return (
    <>
      {comesFromSearchCommerce ? (
        <>
          {(status === FormStatusEnum.IN_PROCESS ||
            status === FormStatusEnum.FINISHED ||
            status === FormStatusEnum.STARTED_BY_OTHER) && (
            <InfoBlock title="Información básica del comercio" fields={basicInfoUpdated} />
          )}
          {(status === FormStatusEnum.IN_PROCESS || status === FormStatusEnum.FINISHED) &&
            !contractsSigned && (
              <>
                <InfoBlock title="Contratos" fields={contractsUpdated} />
                {!uploadedContract && (
                  <ShareBlock
                    email={businessData?.principalEmail!}
                    commerceId={commerceId}
                    rut={String(businessData.rut)}
                    handleStatus={handleStatus}
                  />
                )}
              </>
            )}
          {showECommerceBlock && (
            <ECommerceBlock
              title="E-Commerce"
              formRef={ecommerceAndTariffsForm}
              businessSector={businessData.businessCategory?.externalId}
              businessId={businessData.id}
              setIsLoading={setIsLoading}
            />
          )}
        </>
      ) : (
        <>
          {businessData?.branches?.map((branch: NewBranchValues, index: number) => {
            const updatedFields = updateDuplicatedBlockFields('branch', branch);
            return (
              <InfoBlock
                title={`Sucursal ${index + 1}`}
                fields={updatedFields}
                key={index}
                isLastLoop={index === businessData?.branches.length - 1}
              />
            );
          })}
          {beneficiaries?.map((beneficiary: NewBeneficiaryValues, index: number) => {
            const updatedFields = updateDuplicatedBlockFields('beneficiary', beneficiary);
            return (
              <InfoBlock
                title={`Beneficiario final ${index + 1}`}
                fields={updatedFields}
                key={index}
                isLastLoop={index === beneficiaries.length - 1}
              />
            );
          })}
          <InfoBlock title="Domicilio principal" fields={mainAddressUpdated} />
          <InfoBlock title="Cuenta bancaria" fields={bankAccountUpdatedFields} />
          <InfoBlock title="Actividad económica" fields={economicActivityUpdated} />
          <InfoBlock title="Información tributaria" fields={tributaryInfoUpdated}>
            <div className="confirmation-laws">
              {lawsUpdated?.map((law: InfoBlockField, index: number) =>
                law.value ? (
                  <div key={index} className="confirmation-info info-fieldname semibold">
                    {law.name}
                  </div>
                ) : null,
              )}
            </div>
          </InfoBlock>
          <InfoBlock title="Red de terminales" fields={terminalsUpdated} />
          <TariffBlock
            isStandarTariff={businessData?.standard}
            customTariff={businessData?.tariff}
            businessTaxPayerType={businessData.taxpayerType!}
            acceptMaster={businessData.acceptMaster}
            acceptVisa={businessData.acceptVisa}
            businessCategory={businessData.businessCategory}
          />
          <Attachments attachments={attachments} showAttachments />
        </>
      )}
    </>
  );
}

export default BusinessInfo;
