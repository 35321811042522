export enum FormStatusEnum {
  EMPTY,
  STARTED_BY_OTHER,
  FINISHED,
  IN_PROCESS,
  FREE,
  PENDING,
  SIGNED,
  NOT_SENDED,
  DOCS_SIGNED,
}
