import { Button, Card, Modal } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';

interface ConfirmUploadContractModalProps {
  show: boolean;
  toggleModal: () => void;
  handleUploadFile: () => void;
}

const ConfirmUploadContractModal = ({
  show,
  toggleModal,
  handleUploadFile,
}: ConfirmUploadContractModalProps) => {
  return (
    <Modal show={show} onHide={toggleModal} centered>
      <Card className="modal-card confirm-bank-card">
        <CardHeader>
          <h1>Si realiza la carga manual, el estado del contrato será "Firmado"</h1>
        </CardHeader>
        <Card.Body>
          <Button className="primary-outlined-button w-100" onClick={() => handleUploadFile()}>
            Confirmar &gt;
          </Button>
          <Button className="primary-outlined-button w-100" onClick={toggleModal}>
            &lt; Cancelar
          </Button>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default ConfirmUploadContractModal;
