import { ReactNode } from 'react';
import clsx from 'clsx';
import { InfoBlockField } from '../../types/add_commerce.types';

interface InfoBlockProps {
  title: string;
  fields?: InfoBlockField[];
  isLastLoop?: boolean;
  children?: ReactNode;
}

function InfoBlock({ title, fields, children, isLastLoop }: InfoBlockProps) {
  return (
    <div className={clsx('confirmation-card', isLastLoop === false && 'border-none')}>
      <p className="confirmation-title">{title.toUpperCase()}</p>
      {fields?.map((field: InfoBlockField, index: number) => (
        <div className="confirmation-info" key={`${title}_${field?.name}_${index}`}>
          <div className="info-fieldname">{field?.name}</div>
          <div className="info semibold">{field?.value}</div>
        </div>
      ))}
      {children}
    </div>
  );
}

export default InfoBlock;
