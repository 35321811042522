import { CreateEcommerceRequest } from '../types/business/request';
import axios from './axios';

export const createEcommerce = async (businessId: number, ecommerceRequest: CreateEcommerceRequest) => {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const res = await axios.post('/ecommerce', { ecommerceRequest }, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
};
