import { ChangeEvent } from 'react';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { BusinessTableColumn, CustomTableChunk } from '../../../types/add_commerce.types';

interface PersonalaizedTariffProps {
  tableData: Array<CustomTableChunk>;
  headings: string[];
  field: string;
  setFieldValue: (field: string, value: CustomTableChunk[]) => void;
  handleChangePersonalaizedTable: (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
    currency: BusinessTableColumn,
  ) => void;
  handleBlurPersonalaizedTable?: any;
  editableThirdRow?: boolean;
}

function PersonalaizedTariffTable({
  tableData,
  setFieldValue,
  field,
  headings,
  handleChangePersonalaizedTable,
  handleBlurPersonalaizedTable,
  editableThirdRow = false,
}: PersonalaizedTariffProps) {
  const handleOnChangeTableData = (event: ChangeEvent<HTMLInputElement>, column: BusinessTableColumn) =>
    handleChangePersonalaizedTable(event, tableData, setFieldValue, field, column);

  const handleOnBlurTableData = (event: ChangeEvent<HTMLInputElement>, column: BusinessTableColumn) =>
    handleBlurPersonalaizedTable(event, tableData, setFieldValue, field, column);

  return (
    <CustomTable
      tableData={tableData}
      headings={headings}
      editableSecondRow={true}
      editableThirdRow={editableThirdRow}
      onChangeTableData={handleOnChangeTableData}
      onBlurTableData={handleOnBlurTableData}
    />
  );
}

export default PersonalaizedTariffTable;
