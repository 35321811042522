import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SpinnerProps {
  visible: boolean;
  color?: string;
  size?: SizeProp;
  justSpinner?: boolean;
  containerStyle?: string;
}

function Spinner({
  visible,
  color = '#ec0000',
  size = '8x',
  justSpinner = false,
  containerStyle = 'row justify-content-center mt-5 pt-5',
}: SpinnerProps) {
  const spinner: JSX.Element = (
    <FontAwesomeIcon
      className={visible ? 'd-inline-block' : 'd-none'}
      color={color}
      size={size}
      fixedWidth
      icon={faSpinner as IconProp}
      spin
    />
  );

  return justSpinner ? spinner : <div className={visible ? containerStyle : 'd-none'}>{spinner}</div>;
}

export default Spinner;
