import {
  AddressTaxesRequest,
  BankAccountRequest,
  BasicInfoRequest,
  Beneficiary,
  ConfirmBusinessRequest,
  OfficeBranch,
  TerminalDataRequest,
  TerminalPlanRequest,
  UpdateBusinessBeneficiariesRequest,
  UpdateBusinessBranchRequest,
  UpdateConditionsAndTariffsRequest,
  UpdateFilesRequest,
} from '../types/business/request';
import { dataToUpperCase } from '../utils/helpers';
import { contractFormData, getParsedFormData } from '../utils/serviceHelpers';
import axios from './axios';

export async function getCommerce(rut: number) {
  try {
    const headers = { headers: { rut: rut.toString().padStart(12, '0') } };
    const res = await axios.get('/business', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function basicInfo(basicInfoRequest: BasicInfoRequest) {
  try {
    basicInfoRequest = dataToUpperCase(basicInfoRequest);
    const res = await axios.put('/business/basic-info', basicInfoRequest);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updateBusinessAddressAndTaxes(
  businessId: number,
  addressTaxesRequest: AddressTaxesRequest,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const res = await axios.patch('/business/address-taxes', addressTaxesRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updateBusinessBranchOffices(
  businessId: number,
  updateBusinessBranchRequest: UpdateBusinessBranchRequest,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    updateBusinessBranchRequest.officesBranch.map((branch: OfficeBranch) => dataToUpperCase(branch));
    const res = await axios.put('/business/branch-office', updateBusinessBranchRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updateBusinessBeneficiaries(
  businessId: number,
  updateBusinessBeneficiariesRequest: UpdateBusinessBeneficiariesRequest,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    updateBusinessBeneficiariesRequest.beneficiaries.map((beneficiary: Beneficiary) =>
      dataToUpperCase(beneficiary),
    );
    const res = await axios.put('/business/beneficiary', updateBusinessBeneficiariesRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updateBankAccount(businessId: number, bankAccountRequest: BankAccountRequest) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    bankAccountRequest = dataToUpperCase(bankAccountRequest);
    const res = await axios.patch('/business/bank-account', bankAccountRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updateBusinessDocuments(businessId: number, updateFilesRequest: UpdateFilesRequest) {
  try {
    const formData = getParsedFormData(updateFilesRequest);

    const headers = {
      headers: { businessId: businessId.toString(), 'Content-Type': 'multipart/form-data' },
    };
    const res = await axios.patch('/business/documents', formData, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function confirm(businessId: number, confirmRequest: ConfirmBusinessRequest) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const res = await axios.patch('/business/confirm', confirmRequest, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getStates() {
  try {
    const res = await axios.get('/location/states');
    return res.data.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getCities(stateId: number) {
  try {
    const res = await axios.get(`/location/cities?stateId=${stateId}`);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getNeighborhoods(cityId: number) {
  try {
    const res = await axios.get(`/location/neighborhoods?cityId=${cityId}`);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getBusinessCategories() {
  try {
    const res = await axios.get('/business-categories');
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getTaxPayers() {
  try {
    const res = await axios.get('/tax-payer');
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getBusinessPlansTableData(taxpayerType: string, businessSector: string) {
  const headers = { headers: { taxpayerType, businessSector } };
  try {
    const res = await axios.get('/tax-payer/table', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getTariffsByBusinessSector(businessSector: number) {
  try {
    const res = await axios.get(`/business-categories/table/${businessSector}`);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function deleteBusinessBranch(businessId: number, branchId: number) {
  try {
    const headers = {
      headers: { businessId: businessId.toString(), branchId: branchId.toString() },
    };

    const res = await axios.delete('business/branch', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function deleteBeneficiary(businessId: number, branchId: number, beneficiaryId: number) {
  try {
    const headers = {
      headers: {
        businessId: businessId.toString(),
        branchId: branchId.toString(),
        beneficiaryId: beneficiaryId.toString(),
      },
    };

    const res = await axios.delete('business/beneficiary', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function deleteTerminal(businessId: number, terminalId: number) {
  try {
    const headers = {
      headers: { businessId: businessId.toString(), terminalId: terminalId.toString() },
    };

    const res = await axios.delete('business/terminal', headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updateBusinessExecutive(businessId: number) {
  try {
    await axios.put(`/business/${businessId}/executive`);

    return true;
  } catch (err: any) {
    return err.response;
  }
}

export async function saveTerminalInformation(terminalData: TerminalDataRequest, businessId: number) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const res = await axios.put('/business/terminal', terminalData, headers);

    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function terminalPlan(terminalPlanData: TerminalPlanRequest, businessId: number) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const res = await axios.put('/business/terminal-plan', terminalPlanData, headers);

    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getTerminalsPrice(terminalId: string, terminalQuantity: string) {
  try {
    const res = await axios.get(`/business/terminal-price/${terminalId}/${terminalQuantity}`);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getTerminals() {
  try {
    const res = await axios.get('/business/terminals');
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function updateConditionsTariff(
  businessId: number,
  conditionsAndTariffInformation: UpdateConditionsAndTariffsRequest,
) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const res = await axios.patch('/business/conditions-tariff', conditionsAndTariffInformation, headers);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getBusinessContract(id: number) {
  try {
    const res = await axios.get(`/vafirma/business-vafirma/${id}`);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getDgiBusinessInfo(businessId: number) {
  try {
    const res = await axios.get(`/dgi/business-info/${businessId}`);
    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function resendVaFirmaContract(businessId: number, email: string) {
  try {
    const headers = { headers: { businessId: businessId.toString() } };
    const data = { email };

    const res = await axios.post(`/vafirma/resend-signature-request/${businessId}`, data, headers);

    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function downloadSignatureRequest(docUUID: string) {
  try {
    const res = await axios.get('/vafirma/download-signature-request', {
      params: { docUUID },
    });
    return res.data;
  } catch (error: any) {
    return error.response;
  }
}

export async function validateBankAccounts(
  businessId: number,
  validateBankAccountRequest: BankAccountRequest,
) {
  try {
    const headers = { headers: { businessId: String(businessId) } };
    validateBankAccountRequest = dataToUpperCase(validateBankAccountRequest);

    const res = await axios.post('/business/bank-account/validate', validateBankAccountRequest, headers);

    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function uploadContract(file: File, businessId: number) {
  try {
    const headers = {
      headers: { businessId: businessId.toString(), 'Content-Type': 'multipart/form-data' },
    };
    const formData = contractFormData(file);

    const res = await axios.post(`/contract/upload-contract/${businessId}`, formData, headers);

    return res.data;
  } catch (err: any) {
    return err.response;
  }
}

export async function getBusinessTypes(businessCategoryId: number) {
  try {
    const response = await axios.get(`/business/type/${businessCategoryId}`);

    return response.data;
  } catch (err: any) {
    return err.response;
  }
}
