import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import BusinessLogo from '../../../assets/business.png';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { deleteBusinessBranch, updateBusinessBranchOffices } from '../../../services/commerce.service';
import {
  City,
  CommerceBranchesState,
  Neighborhood,
  NewBranchValuess,
  State,
} from '../../../types/add_commerce.types';
import { OfficeBranch } from '../../../types/business/request';
import { newBranchh } from '../../../utils/data';
import { doRefsSubmissions } from '../../../utils/formikHelpers';
import { triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import NewBranch from './NewBranch';

function CommerceBranches() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRefs = useRef<any>([]);
  const localState: CommerceBranchesState = location.state as CommerceBranchesState;

  const [branches, setBranches] = useState<Array<NewBranchValuess>>(
    localState?.branches?.length ? localState.branches : [{ ...newBranchh, localId: uuidv4() }],
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submittedBranches, setSubmittedBranches] = useState<Array<NewBranchValuess>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const submitBranch = (
    values: NewBranchValuess,
    index: number,
    businessCategoryId: string | undefined,
    state: State,
    city: City,
    neighborhood: Neighborhood,
  ) => {
    const updateArray = [...submittedBranches];

    if (index === 0) {
      const branchOfficeSubCategoryId: string = location.state.businessCategoryId.toString();

      updateArray[index] = {
        ...values,
        businessCategoryId: branchOfficeSubCategoryId,
        state,
        city,
        neighborhood,
      };
    } else {
      updateArray[index] = { ...values, businessCategoryId, state, city, neighborhood };
    }

    setSubmittedBranches(updateArray);
  };

  const postBranches = async () => {
    setLoading(true);
    const officesBranch: OfficeBranch[] = submittedBranches?.map((branch) => ({
      fantasyName: branch.fantasyName,
      neighborhood: branch.neighborhood?.id.toString()!,
      city: branch?.city?.id ? branch?.city?.id?.toString() : branch?.city?.toString(),
      addressStreet: branch.addressStreet,
      addressNumber: branch.addressNumber?.toString()!,
      addressFlatNumber: branch.addressFlatNumber?.toString()! || '',
      addressSolar: branch.addressSolar?.toString()! || '',
      addressPlot: branch.addressPlot?.toString()! || '',
      businessSubCategory: branch.businessCategoryId,
    }));

    const response = await updateBusinessBranchOffices(localState.id!, { officesBranch });

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;

      setLoading(false);

      return navigate(pagesPaths.CommerceBeneficiaries, { state: { ...localState, ...businessData } });
    }
    triggerToasts(response);
    setLoading(false);
  };

  useEffect(() => {
    if (submitted && submittedBranches.length === branches.length) {
      setSubmitted(false);
      postBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, submittedBranches, localState, navigate]);

  const handleGoBack = () => navigate(pagesPaths.CommerceAddress, { state: { ...localState } });

  const handleSubmit = () => {
    if (formRefs.current) {
      const didFormRefsSubmitted: boolean = doRefsSubmissions(formRefs);
      didFormRefsSubmitted && setSubmitted(true);
    }
  };

  const handleAddNewBranch = () => setBranches([...branches, { ...newBranchh, localId: uuidv4() }]);

  const handleDeleteBranch = async (index: number) => {
    const branchToDelete = branches.find(
      (branch: NewBranchValuess, filterIndex: number) => index === filterIndex,
    );
    if (branchToDelete?.id) {
      const response = await deleteBusinessBranch(localState.id!, branchToDelete?.id);

      if (response.result) {
        return setBranches(response.data.branches);
      }
      return triggerToasts(response);
    }

    const branchesFiltered = branches.filter(
      (branch: NewBranchValuess, filterIndex: number) => index !== filterIndex,
    );

    setBranches(branchesFiltered);
  };

  return (
    <>
      <Container className="branches">
        <ProgressBar now={(100 / 7) * 3} />
        <Row>
          <Col lg={12}>
            <h1>
              <img src={BusinessLogo} alt="Comercio" className="me-3" /> Sucursales
            </h1>
            <br />
            {branches?.map((branch: NewBranchValuess, index: number) => (
              <NewBranch
                innerRef={(formElement: any) => (formRefs.current[index] = formElement)}
                submitBranch={submitBranch}
                key={branch.localId || `${branch.fantasyName}_${index}`}
                index={index}
                branchData={branch}
                handleDelete={handleDeleteBranch}
              />
            ))}
          </Col>
        </Row>
        <div>
          <Button onClick={handleAddNewBranch} className="add-section">
            <FontAwesomeIcon icon={faPlus as IconProp} />
            Agregar sucursal
          </Button>
        </div>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={loading} />
    </>
  );
}

export default CommerceBranches;
