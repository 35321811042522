import { PhoneCountryCodesEnum } from '../types/enums/phone-country-codes.enum';

export const getPhonePlaceHolder = (phoneCountryCode: PhoneCountryCodesEnum) => {
  switch (phoneCountryCode) {
    case PhoneCountryCodesEnum.UY:
      return 'Ej: 099123123';
    case PhoneCountryCodesEnum.AR:
      return 'Ej: 11 1234-5678 (Sin espacios ni guiones)';
    case PhoneCountryCodesEnum.BR:
      return 'Ej: 12345678910';
    case PhoneCountryCodesEnum.PY:
      return 'Ej: 912345678';
    default:
      return '';
  }
};
