import { useNavigate } from 'react-router-dom';
import { Button, Card, Modal } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';

interface AddCommerceSuccessModalProps {
  show: boolean;
  toggleModal: () => void;
}

const AddCommerceSuccessModal = ({ show, toggleModal }: AddCommerceSuccessModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal show={show} onHide={toggleModal} centered>
      <Card className="modal-card confirm-bank-card">
        <CardHeader>
          <h1>Cliente ingresado exitosamente</h1>
        </CardHeader>
        <Card.Body>
          <Button className="primary-outlined-button w-100" onClick={() => navigate('/')}>
            Volver al inicio &gt;
          </Button>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default AddCommerceSuccessModal;
