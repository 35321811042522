import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import logo from '../../assets/logo-getnet-full@2x.png';
import Input from '../../components/Input/Input';
import { restorePassword } from '../../services/user.service';
import schemas from '../../utils/schemas';

interface ActivateAccountValues {
  document: number | null;
  password: string;
  confirmPassword: string;
}

function RestorePassword() {
  const emailTokenParams = useParams();
  const navigate = useNavigate();
  const token: string = emailTokenParams.token!;
  const [error, setError] = useState<string>('');

  const submitActivation = async (values: ActivateAccountValues) => {
    try {
      const restorePasswordResponse = await restorePassword(
        values.password,
        values.confirmPassword,
        Number(values.document),
        token!,
      );

      if (restorePasswordResponse.result) {
        setError('');
        navigate('/resultado', {
          state: { activeUserResponse: restorePasswordResponse.result, isFromRestorePassword: true },
        });
      } else {
        setError(restorePasswordResponse.message);
      }
    } catch (err: any) {
      setError(err.response.data.message!);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          <Card>
            <CardHeader>
              <img src={logo} alt="Getnet" className="logo" />
              <h1>Recuperar contraseña</h1>
            </CardHeader>
            <Card.Body>
              <p>Establece una nueva contraseña</p>
              <Formik
                onSubmit={submitActivation}
                initialValues={{ document: null, password: '', confirmPassword: '' }}
                validationSchema={schemas.ActivationSchema}
              >
                <Form>
                  <Input
                    className="position-relative pb-3"
                    type="number"
                    name="document"
                    placeholder="Documento de identidad"
                  />
                  <Input
                    className="position-relative pb-3"
                    type="password"
                    name="password"
                    placeholder="Nueva contraseña"
                  />
                  <Input
                    className="position-relative pb-3"
                    type="password"
                    name="confirmPassword"
                    placeholder="Repetir contraseña"
                  />
                  <Button type="submit" className="primary-outlined-button w-100">
                    Restablecer contraseña
                  </Button>
                  {error && <p className="activation-error">{error}</p>}
                </Form>
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default RestorePassword;
