export enum FormStepEnum {
  NONE,
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
  SEVENTH,
  EIGHTH,
  NINETH,
  TENTH,
  DONE,
}
