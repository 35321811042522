import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FormStatusEnum } from '../../types/enums/form-status.enum';

interface StatusValues {
  status: FormStatusEnum | string;
  isStatusBorderNeeded?: boolean;
}

function Status({ status, isStatusBorderNeeded = true }: StatusValues) {
  const [statusStyle, setStatusStyle] = useState<any>(null);
  const [statusValue, setStatusValue] = useState<any>(null);

  useEffect(() => {
    const getStatusStyle = (status: string | FormStatusEnum) => {
      switch (status) {
        case FormStatusEnum[FormStatusEnum.FINISHED]:
        case FormStatusEnum[FormStatusEnum.SIGNED]:
        case FormStatusEnum[FormStatusEnum.NOT_SENDED]:
          return 'status-green';

        case FormStatusEnum[FormStatusEnum.STARTED_BY_OTHER]:
          return 'status-yellow';

        default:
          return 'status-red';
      }
    };

    const getStatusValue = (status: string | FormStatusEnum) => {
      switch (status) {
        case FormStatusEnum[FormStatusEnum.FINISHED]:
          return 'Finalizado';

        case FormStatusEnum[FormStatusEnum.STARTED_BY_OTHER]:
          return 'Enviado a firmar';

        case FormStatusEnum[FormStatusEnum.IN_PROCESS]:
          return 'Pendiente';

        case FormStatusEnum[FormStatusEnum.DOCS_SIGNED] ||
          FormStatusEnum[FormStatusEnum.SIGNED] ||
          FormStatusEnum[FormStatusEnum.NOT_SENDED]:
          return 'Firmado';

        case FormStatusEnum[FormStatusEnum.PENDING]:
          return 'Enviado a firmar';

        default:
          return 'Sin completar';
      }
    };

    setStatusStyle(getStatusStyle(status));
    setStatusValue(getStatusValue(status));
  }, [status]);

  return (
    <div className="row d-flex justify-content-between status-container col-12 col-md-11 position-relative">
      <p className="col-3 status-label">ESTADO</p>
      <p className={clsx('col-sm-3 col-md-3 py-2 px-3 status-value', statusStyle)}>{statusValue}</p>
      {isStatusBorderNeeded && <span className="status-border" />}
    </div>
  );
}

export default Status;
