const successChangePassword = {
  title: '¡Email enviado!',
  text: 'Por favor revisa tu correo electrónico y sigue los pasos para recuperar la contraseña',
  sendEmailAgain:
    '¿No recibiste el correo? Si no lo recibiste en unos instantes en la bandeja de entrada o en spam, haz clic en',
};

const errorChangePassword = {
  title: '¡Ocurrio un error al enviar el mail!',
  text: 'Vuelve a intentarlo en unos minutos.',
};

const successActivateAccount = {
  title: '¡Bienvenido!',
  text: 'Ya podés ingresar con tu mail y contraseña.',
};

const alreadyActivateAccount = {
  text: 'Este usuario ya está activo o el token es inválido.',
};

const successResetPassword = {
  title: 'Contraseña restablecida',
  text: 'Ya podés ingresar con tu mail y tu nueva contraseña.',
};

const resultMessage = {
  successChangePassword,
  errorChangePassword,
  successActivateAccount,
  alreadyActivateAccount,
  successResetPassword,
};

export default resultMessage;
