import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import reconciliation from '../../../assets/Reconciliation.png';
import documentation from '../../../assets/documentation.png';
import BusinessInfo from '../../../components/BusinessInfo/BusinessInfo';
import Input from '../../../components/Input/Input';
import RoundedCheckbox from '../../../components/Input/RadioButton';
import Select from '../../../components/Input/Select';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { confirm } from '../../../services/commerce.service';
import { AddCommerceSixthAndFinalStepLocationState } from '../../../types/add_commerce.types';
import { ConfirmBusinessRequest } from '../../../types/business/request';
import { SendContractOptions } from '../../../types/enums/contract-options.enum';
import { ErrorsEnum } from '../../../types/enums/errors.enum';
import { PhoneCountryCodesEnum } from '../../../types/enums/phone-country-codes.enum';
import { countriesPhoneCodes } from '../../../utils/data';
import { triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import { getPhonePlaceHolder } from '../../../utils/phoneCountriesCodesHelpers';
import schemas from '../../../utils/schemas';
import ConfirmNotValidatedEmailModal from '../Contracts/ConfirmNotValidatedEmailModal';
import AddCommerceSuccessModal from './AddCommerceSuccessModal';
import BankValidationWarningModal from './BankValidationWarningModal';

function CommerceSummary() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef<any>();

  const localState: AddCommerceSixthAndFinalStepLocationState =
    location.state as AddCommerceSixthAndFinalStepLocationState;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showNotValidatedEmailModal, setShowNotValidatedEmailModal] = useState<boolean>(false);
  const [unknownEmail, setUnknownEmail] = useState<boolean>(false);
  const [showBankWarningModal, setShowBankWarningModal] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});

  const handleGoBack = () => navigate(pagesPaths.CommerceDocumentation, { state: { ...localState } });

  const submitConfirm = async (values: any) => {
    if (!localState.hasBankAccountsValidated && !showBankWarningModal) {
      setFormValues(values);
      return setShowBankWarningModal(true);
    }

    setShowBankWarningModal(false);
    setIsLoading(true);

    // If is an uruguayan number we delete the 0 at the begining (if it has it)
    values.phoneNumber =
      values.phoneNumber && values.phoneNumberCountryCode === PhoneCountryCodesEnum.UY
        ? String(values.phoneNumber).replace(/^0/, '')
        : values.phoneNumber;

    let requestBody: ConfirmBusinessRequest = {
      principalEmail: values.principalEmail,
      principalAccessToPortal: true,
      secondaryEmail: values?.secondaryEmail,
      secondaryAccessToPortal: values?.secondaryAccess === 'yes',
      unknownEmail: unknownEmail,
      phoneNumber: values?.phoneNumberCountryCode + values.phoneNumber,
      sendContractsOption: values?.sendContractsOption,
    };

    const response = await confirm(localState.id!, requestBody);

    setIsLoading(false);

    if (response.result) {
      return navigate(pagesPaths.CommerceContracts, {
        state: { ...localState, principalEmail: values.principalEmail, ...response.data },
      });
    } else {
      if (response.data.errorCode === ErrorsEnum.UNKNOWN_EMAIL) {
        setUnknownEmail(true);
        setShowNotValidatedEmailModal(true);
      } else {
        triggerToasts(response.data.message);
      }
    }
  };

  const handleSubmit = () => {
    localState?.hideEmailSection
      ? navigate(pagesPaths.CommerceContracts, { state: localState })
      : formRef.current.submitForm();
  };

  const toggleModal = () => setShowModal(!showModal);

  const toggleNotValidatedEmailModal = () => {
    setShowNotValidatedEmailModal(!showNotValidatedEmailModal);
  };

  const handleSendContracts = () => {
    toggleNotValidatedEmailModal();
    handleSubmit();
  };

  const handleEditEmail = () => {
    setUnknownEmail(false);
    toggleNotValidatedEmailModal();
  };

  const toggleBankWarning = () => {
    setShowBankWarningModal(!showBankWarningModal);
  };

  const handleChangeSendContractsOption = (
    value: string,
    setFieldValue: (field: string, value: string) => void,
  ) => setFieldValue('sendContractsOption', value);

  return (
    <>
      <ConfirmNotValidatedEmailModal
        show={showNotValidatedEmailModal}
        toggleModal={toggleNotValidatedEmailModal}
        handleSendContracts={handleSendContracts}
        handleEditEmail={handleEditEmail}
      />
      <BankValidationWarningModal
        show={showBankWarningModal}
        toggleModal={toggleBankWarning}
        submitConfirm={submitConfirm}
        formValues={formValues}
      />
      <AddCommerceSuccessModal show={showModal} toggleModal={toggleModal} />
      <Container className="confirmation">
        <ProgressBar now={100} />
        <Row>
          {!localState?.hideEmailSection && (
            <Formik
              initialValues={{
                principalEmail: '',
                principalAccess: '',
                secondaryEmail: '',
                secondaryAccess: '',
                sendContractsOption: '',
                phoneNumber: localState.cellphone || '',
                phoneNumberCountryCode: PhoneCountryCodesEnum.UY,
              }}
              validationSchema={schemas.CommerceSummarySchema}
              innerRef={(formElement: any) => (formRef.current = formElement)}
              onSubmit={submitConfirm}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <h2 className="summary-title">
                    <img src={documentation} alt="Comercio" className="me-3" /> Envío de documentación
                  </h2>
                  <Row>
                    <Input
                      className="input col-md-6"
                      type="text"
                      name="principalEmail"
                      label="Email Principal"
                      required
                    />
                    <div className="summary-phone-container">
                      <Select
                        required
                        label="Teléfono"
                        className="summary-phone-code"
                        name="phoneNumberCountryCode"
                      >
                        {countriesPhoneCodes.map(({ value, label }) => (
                          <option value={value}>
                            {label} {value}
                          </option>
                        ))}
                      </Select>
                      <Input
                        className="input col-md-6 summary-phone-number"
                        type="number"
                        name="phoneNumber"
                        label=" "
                        placeholder={getPhonePlaceHolder(values.phoneNumberCountryCode)}
                      />
                    </div>
                  </Row>
                  <Row>
                    <p>
                      Seleccione el medio de envío de la documentación <span className="required">*</span>
                    </p>
                    <RoundedCheckbox
                      customClass="send-first-option"
                      label="Sólo al correo"
                      name="sendContractsOption"
                      onChange={handleChangeSendContractsOption}
                      setFieldValue={setFieldValue}
                      value={SendContractOptions.EMAIL}
                      currentComboCheckboxValue={values.sendContractsOption}
                    />
                    <RoundedCheckbox
                      customClass="send-second-option"
                      label="Correo y teléfono (Whatsapp)"
                      name="sendContractsOption"
                      onChange={handleChangeSendContractsOption}
                      setFieldValue={setFieldValue}
                      value={SendContractOptions.BOTH}
                      currentComboCheckboxValue={values.sendContractsOption}
                    />
                  </Row>
                  <Row className="portal-access-mail-row">
                    <Input
                      className="input col-md-6"
                      type="text"
                      name="secondaryEmail"
                      label="Otros emails"
                    />
                    <Select className="col-md-6" name="secondaryAccess" label="Acceder al portal web">
                      <option value="yes">Sí</option>
                      <option value="no">No</option>
                    </Select>
                  </Row>
                </Form>
              )}
            </Formik>
          )}
          <Col lg={12}>
            <h2 className="summary-title">
              <img src={reconciliation} alt="Comercio" className="me-3" /> Confirmación y firma del contrato
            </h2>
            <BusinessInfo businessData={localState} showAttachments />
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={isLoading} />
    </>
  );
}

export default CommerceSummary;
