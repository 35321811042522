interface RoundedCheckboxProps {
  label: string;
  value: string;
  onChange: (value: string, setFieldValue: any) => void;
  setFieldValue: (name: string, value: string) => void;
  name: string;
  customClass?: string;
  currentComboCheckboxValue: string;
}

function RoundedCheckbox({
  label,
  value,
  onChange,
  setFieldValue,
  name,
  customClass,
  currentComboCheckboxValue,
}: RoundedCheckboxProps) {
  return (
    <div className={`form-check form-check-inline ${customClass}`}>
      <input
        className="form-check-input radio-button"
        type="radio"
        id={name}
        onChange={(e) => onChange(e.target.value, setFieldValue)}
        name={name}
        value={value}
        checked={value === currentComboCheckboxValue}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
}

export default RoundedCheckbox;
