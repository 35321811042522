import { useRef, ChangeEvent, FocusEvent } from 'react';
import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import { Col } from 'react-bootstrap';
import fileUploadLogo from '../../assets/file-upload-solid.svg';
import { getFilenameFromUrl } from '../../utils/helpers';

interface FileInputProps {
  name: string;
  file: File | string | null;
  setFile: (
    event: ChangeEvent<HTMLInputElement>,
    name: string,
    setFieldValue: (name: string, value: number) => void,
  ) => void;
  isHalf?: boolean;
  index?: number;
}

const FileInput = ({ setFile, file, name, isHalf, index }: FileInputProps) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const [, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (event.type === 'focus') {
      labelRef?.current?.classList.add('label-focus');
    } else {
      labelRef?.current?.classList.remove('label-focus');
    }
  };

  const getSpanTextByFileType = (file: File | string | null) => {
    if (!file) {
      return 'Adjuntá tu archivo.';
    }
    if (typeof file === 'string') {
      return getFilenameFromUrl(file);
    }
    return file.name;
  };

  return (
    <Col className="input-file-div" md={isHalf ? 6 : 12}>
      <label
        htmlFor={index ? `${name}_${index}` : name}
        className={clsx('input-file-label', meta.error && meta.touched && 'label-error')}
        ref={labelRef}
      >
        <input
          type="file"
          id={index ? `${name}_${index}` : name}
          name={name}
          accept="image/jpg, image/png, image/jpeg, application/pdf"
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFile(event, name, setFieldValue)}
          onFocus={handleFocus}
          onBlur={handleFocus}
        />
        <span className="iborrainputfile">
          <img src={fileUploadLogo} className="preview-img" alt="" />
          {getSpanTextByFileType(file)}
        </span>
        <p>Debe estar en formato JPG, PNG o PDF y no pesar más de 5MB.</p>
      </label>
      {meta.error && meta.touched && <span className="text-error form-text">{meta.error}</span>}
    </Col>
  );
};

export default FileInput;
