import { Form, Formik } from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import IssuerLogo from '../../../assets/Issuer.svg';
import CustomTable from '../../../components/CustomTable/CustomTable';
import Input from '../../../components/Input/Input';
import RoundedCheckbox from '../../../components/Input/RadioButton';
import Select from '../../../components/Input/Select';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { businessSectorService } from '../../../services/business-sector-table';
import { getBusinessPlansTableData, updateConditionsTariff } from '../../../services/commerce.service';
import { getTea } from '../../../services/tea.service';
import {
  ConditionsAndTariffLocationState,
  CustomTableChunk,
  InfoBlockField,
} from '../../../types/add_commerce.types';
import { UpdateConditionsAndTariffsRequest } from '../../../types/business/request';
import { BusinessSectorEnum } from '../../../types/enums/business-sector.enum';
import { ConditionsTariffEnum } from '../../../types/enums/conditions-tariff.enum';
import { CurrencyEnum } from '../../../types/enums/currency.enum';
import {
  conditionsAndTariffPersonalaizedMastercardData as conditionsAndTariffPersonalaizedMastercardDataDefault,
  conditionsAndTariffPersonalaizedVisaData as conditionsAndTariffPersonalaizedVisaDataDefault,
  currencies,
  masterPersonalizedComisionHeadings,
  standarComisionHeadings,
  visaPersonalizedComisionHeadings,
} from '../../../utils/data';
import {
  addPercentaje,
  capitalize,
  formatTableDataForTariffsMaster,
  formatTableDataForTariffsVisa,
  getEnumTypeValue,
  triggerToasts,
} from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import schemas from '../../../utils/schemas';
import PersonalaizedTariffTable from './PersonalaizedTariff';

function CommerceConditions() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preSettedMastercardTariff, setPreSettedMastercardTariff] = useState<any[]>([]);
  const [preSettedVisaTariff, setPreSettedVisaTariff] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef<any>(null);
  const [tableDataVisa, setTableDataVisa] = useState<any[]>([]);
  const [tableDataMaster, setTableDataMaster] = useState<any[]>([]);
  const [tea, setTea] = useState<string>('');
  const [conditionsAndTariffPersonalaizedVisaData, setVisaPersonalizedTableData] = useState<
    CustomTableChunk[]
  >(conditionsAndTariffPersonalaizedVisaDataDefault);
  const [conditionsAndTariffPersonalaizedMastercardData, setMasterPersonalizedTableData] = useState<
    CustomTableChunk[]
  >(conditionsAndTariffPersonalaizedMastercardDataDefault);

  const localState: ConditionsAndTariffLocationState = location.state as ConditionsAndTariffLocationState;
  const disableCurrencyPlaceholder = localState?.accountTrackingNumber === '';
  const businessSector =
    BusinessSectorEnum[getEnumTypeValue('businessCategory', localState.businessCategory!)];
  let canAccessPersonalizedTariff = localStorage.getItem('canAccessPersonalizedTariff') === 'true';
  const managerHasTariffRestriction = localStorage.getItem('tariffRestriction') === 'true';

  let hasBankAccount = false;
  let currencyToOperate = '';

  if (businessSector === BusinessSectorEnum[BusinessSectorEnum.COMBUSTIBLES] && canAccessPersonalizedTariff) {
    canAccessPersonalizedTariff = !managerHasTariffRestriction;
  }

  if (localState.bankAccounts!.length > 0) {
    hasBankAccount = true;

    let uyuAccount = false;
    let usdAccount = false;

    for (const bankAccount of localState.bankAccounts!) {
      const currency = +bankAccount.accountCurrency;

      if (currency === CurrencyEnum.URUGUAYAN_PESOS) {
        uyuAccount = true;
      } else if (currency === CurrencyEnum.US_DOLLAR) {
        usdAccount = true;
      }
    }

    if (uyuAccount && usdAccount) {
      currencyToOperate = 'ambas';
    } else if (usdAccount) {
      currencyToOperate = '2';
    } else {
      currencyToOperate = '1';
    }
  }

  const fetchTea = async () => {
    const tea = await getTea();

    setTea(tea);
  };

  const fetchPersonalizedTariffValues = async (businessSector: string) => {
    const serviceResponse = await businessSectorService.getBsuinessSectorTable(businessSector);

    setVisaPersonalizedTableData(serviceResponse.visa);
    setMasterPersonalizedTableData(serviceResponse.master);
  };

  useEffect(() => {
    fetchTea();
    fetchPersonalizedTariffValues(localState.businessCategory.name);
  }, []);

  useEffect(() => {
    if (localState.tariff) {
      const masterTariff = conditionsAndTariffPersonalaizedMastercardData[0].rows.map((masterPlan) => {
        switch (masterPlan.plan) {
          case 'DÉBITO':
            masterPlan.currency = Number(localState.tariff?.masterDebit?.replace('%', ''));
            return masterPlan;
          case 'PREPAGO':
            masterPlan.currency = Number(localState.tariff?.masterPrepaid?.replace('%', ''));
            return masterPlan;
          case 'CRÉDITO CONTADO':
            masterPlan.currency = Number(localState.tariff?.masterCashCredit?.replace('%', ''));
            return masterPlan;
          case 'CRÉDITO CUOTAS':
            masterPlan.currency = Number(localState.tariff?.masterInstallmentCredit?.replace('%', ''));
            return masterPlan;
          case 'Extranjeros DÉBITO':
            masterPlan.currency = Number(localState.tariff?.masterForeignDebit?.replace('%', ''));
            return masterPlan;
          case 'Extranjeros CRÉDITO':
            masterPlan.currency = Number(localState.tariff?.masterForeignCredit?.replace('%', ''));
            return masterPlan;
          default:
            break;
        }
      });

      const visaTariff = conditionsAndTariffPersonalaizedVisaData[0].rows.map((masterPlan) => {
        switch (masterPlan.plan) {
          case 'DÉBITO':
            masterPlan.currency = Number(localState.tariff?.visaDebit?.replace('%', ''));
            return masterPlan;
          case 'PREPAGO':
            masterPlan.currency = Number(localState.tariff?.visaPrepaid?.replace('%', ''));
            return masterPlan;
          case 'CRÉDITO CONTADO':
            masterPlan.currency = Number(localState.tariff?.visaCashCredit?.replace('%', ''));
            return masterPlan;
          case 'CRÉDITO CUOTAS':
            masterPlan.currency = Number(localState.tariff?.visaInstallmentCredit?.replace('%', ''));
            return masterPlan;
          case 'Extranjeros DÉBITO':
            masterPlan.currency = Number(localState.tariff?.visaForeignDebit?.replace('%', ''));
            return masterPlan;
          case 'Extranjeros CRÉDITO':
            masterPlan.currency = Number(localState.tariff?.visaForeignCredit?.replace('%', ''));
            return masterPlan;
          default:
            break;
        }
      });

      setPreSettedMastercardTariff([{ rows: masterTariff }]);
      setPreSettedVisaTariff([{ rows: visaTariff }]);
    }
  }, [conditionsAndTariffPersonalaizedMastercardData, conditionsAndTariffPersonalaizedMastercardData]);

  const handleGoBack = () => navigate(pagesPaths.CommerceBankAccount, { state: { ...localState } });

  const handleSubmit = () => formRef.current.handleSubmit();

  const handleBlurPersonalaizedTable = (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
  ) => {
    if (managerHasTariffRestriction) {
      const value = event.target.value;
      const id = event.target.id.split('_')[1];
      const propousalValues = tableData[0].rows[parseInt(id)];
      const max = propousalValues.fees;
      const min = propousalValues.deferred;
      const newTableData = [...tableData];

      if (value > max) {
        newTableData[0].rows[parseInt(id)].currency = max;

        setFieldValue(field, newTableData);
        triggerToasts('El valor ingresado supera el máximo permitido', false);
      } else if (value < min) {
        newTableData[0].rows[parseInt(id)].currency = min;

        setFieldValue(field, newTableData);
        triggerToasts('El valor ingresado es menor al mínimo permitido', false);
      } else setFieldValue(field, newTableData);
    }
  };

  const handleChangePersonalaizedTable = (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
  ) => {
    const value = event.target.value;
    const id = event.target.id.split('_')[1];
    const newTableData = [...tableData];

    newTableData[0].rows[parseInt(id)].currency = value;

    setFieldValue(field, newTableData);
  };

  const submitForm = async (values: any) => {
    setIsLoading(true);

    if (values.visaOperates === 'NO' && values.masterOperates === 'NO') {
      setIsLoading(false);
      return triggerToasts('Debes seleccionar al menos un sello');
    }

    const isStandard: boolean = values.isStandarTariff === ConditionsTariffEnum.STANDAR;
    const requestBody: UpdateConditionsAndTariffsRequest = {
      conditions: {
        averageMonthlyBilling: values.averageMonthlyBilling,
        currency: values.currency,
        anticipatePayments: values.anticipatePayments,
        surchargedFee: values.installmentsWithRecharge,
      },
      tariff: {
        id: localState.tariff?.id ? Number(localState.tariff?.id) : undefined,
        standard: isStandard,
        master:
          values.masterOperates === 'SI'
            ? {
                debit: addPercentaje(values.master[0].rows[0].currency),
                prepaid: addPercentaje(values.master[0].rows[1].currency),
                cashCredit: addPercentaje(values.master[0].rows[2].currency),
                installmentCredit: addPercentaje(values.master[0].rows[3].currency),
                foreignDebit: addPercentaje(values.master[0].rows[4].currency),
                foreignCredit: addPercentaje(values.master[0].rows[5].currency),
              }
            : null,
        visa:
          values.visaOperates === 'SI'
            ? {
                debit: addPercentaje(values.visa[0].rows[0].currency),
                prepaid: addPercentaje(values.visa[0].rows[1].currency),
                cashCredit: addPercentaje(values.visa[0].rows[2].currency),
                installmentCredit: addPercentaje(values.visa[0].rows[3].currency),
                foreignDebit: addPercentaje(values.visa[0].rows[4].currency),
                foreignCredit: addPercentaje(values.visa[0].rows[5].currency),
              }
            : null,
        hasRestriction: managerHasTariffRestriction,
      },
      acceptVisa: values.visaOperates === 'SI',
      acceptMaster: values.masterOperates === 'SI',
    };
    const response = await updateConditionsTariff(localState?.id!, requestBody);
    setIsLoading(false);

    if (response?.result && response?.data) {
      const nextScreen = pagesPaths.CommerceTerminals;
      return navigate(nextScreen, {
        state: { ...localState, ...response.data.businessData, from: pagesPaths.CommerceConditions },
      });
    } else {
      triggerToasts(response);
    }
  };

  const handleChangeTariff = (value: string, setFieldValue: any) => {
    if (value === ConditionsTariffEnum.STANDAR) {
      setFieldValue('visa', tableDataVisa);
      setFieldValue('master', tableDataMaster);
      setFieldValue('isStandarTariff', ConditionsTariffEnum.STANDAR);
    } else {
      setFieldValue('isStandarTariff', ConditionsTariffEnum.PERSONALIZED);
      setFieldValue('visa', conditionsAndTariffPersonalaizedVisaData);
      setFieldValue('master', conditionsAndTariffPersonalaizedMastercardData);
    }
  };

  useEffect(() => {
    const getTableData = async () => {
      const response = await getBusinessPlansTableData(
        getEnumTypeValue('taxPayer', localState.taxpayerType!),
        getEnumTypeValue('businessCategory', localState.businessCategory!),
      );

      if (response.result) {
        const formattedTableDataVisa = formatTableDataForTariffsVisa(response.data);
        setTableDataVisa(formattedTableDataVisa);

        const formattedTableDataMaster = formatTableDataForTariffsMaster(response.data);
        setTableDataMaster(formattedTableDataMaster);
      }
    };

    getTableData();
  }, [localState.businessCategory, localState.taxpayerType]);

  return (
    <>
      <Container className="conditions-and-tariff">
        <ProgressBar now={(7 / 12) * 100} />
        <Row>
          <Col lg={12}>
            <h1>
              <img src={IssuerLogo} alt="issuer-logo" className="me-3" /> Condiciones/Tarifario
            </h1>
            <br />
            <Formik
              innerRef={(formElement: any) => (formRef.current = formElement)}
              validationSchema={schemas.CommerceConditionsSchema}
              onSubmit={submitForm}
              initialValues={{
                averageMonthlyBilling: localState?.averageMonthlyBilling || 0,
                isStandarTariff:
                  !localState.standard && localState.tariff
                    ? ConditionsTariffEnum.PERSONALIZED
                    : ConditionsTariffEnum.STANDAR,
                currency: currencyToOperate || '',
                visa: conditionsAndTariffPersonalaizedVisaData,
                master: conditionsAndTariffPersonalaizedMastercardData,
                installmentsWithRecharge: localState?.currency ? localState?.surchargedFee : undefined,
                anticipatePayments: localState?.currency ? localState?.anticipatePayments : undefined,
                visaOperates: localState?.acceptVisa !== null ? (localState?.acceptVisa ? 'SI' : 'NO') : 'SI',
                masterOperates:
                  localState?.acceptMaster !== null ? (localState?.acceptMaster ? 'SI' : 'NO') : 'SI',
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Row>
                    <Input
                      className="input col-md-6"
                      type="number"
                      name="averageMonthlyBilling"
                      label="Facturación promedio mensual ($UY)"
                    />
                    <Select
                      name="currency"
                      label="Moneda"
                      className="col-md-6"
                      noPlaceholder={disableCurrencyPlaceholder}
                      required
                      disabled={hasBankAccount}
                    >
                      {currencies?.map((currency: InfoBlockField, index: number) => (
                        <option key={index} value={currency.value}>
                          {capitalize(currency.name)}
                        </option>
                      ))}
                      <option key="ambas" value="ambas">
                        Ambas
                      </option>
                    </Select>
                    <Select
                      className="col-md-6"
                      name="installmentsWithRecharge"
                      label="Cuotas con recargo"
                      required
                    >
                      <option key={1} value="true">
                        Sí
                      </option>
                      <option key={2} value="false">
                        No
                      </option>
                    </Select>
                    <Select
                      className="col-md-6"
                      name="anticipatePayments"
                      label={`Solicito anticipar todos mis pagos a 24hs. TEA: ${tea}% TEA y U$S (Dólares) TEA: 7%`}
                      required
                    >
                      <option key={1} value="true">
                        Sí
                      </option>
                      <option key={2} value="false">
                        No
                      </option>
                    </Select>
                    <div className="col-md-6">
                      <RoundedCheckbox
                        name="isStandarTariff"
                        label="Estándar"
                        onChange={handleChangeTariff}
                        setFieldValue={setFieldValue}
                        value={ConditionsTariffEnum.STANDAR}
                        currentComboCheckboxValue={values.isStandarTariff}
                      />
                      {canAccessPersonalizedTariff && (
                        <RoundedCheckbox
                          name="isStandarTariff"
                          label="Personalizado"
                          onChange={handleChangeTariff}
                          setFieldValue={setFieldValue}
                          value={ConditionsTariffEnum.PERSONALIZED}
                          currentComboCheckboxValue={values.isStandarTariff}
                        />
                      )}
                    </div>
                    <div className="issuers-container">
                      <Select
                        className="col-md-12 processor-separator issuer-select"
                        name="visaOperates"
                        label={'¿Operará con VISA?'}
                        required
                      >
                        <option key={1} value="SI">
                          Sí
                        </option>
                        <option key={2} value="NO">
                          No
                        </option>
                      </Select>
                      <Select
                        className="col-md-12 issuer-select"
                        name="masterOperates"
                        label={'¿Operará con MASTER?'}
                        required
                      >
                        <option key={1} value="SI">
                          Sí
                        </option>
                        <option key={2} value="NO">
                          No
                        </option>
                      </Select>
                    </div>
                    {values.isStandarTariff === ConditionsTariffEnum.STANDAR ? (
                      <>
                        {values.visaOperates === 'SI' && (
                          <>
                            <p className="processor-title">VISA</p>
                            <CustomTable tableData={tableDataVisa} headings={standarComisionHeadings} />
                          </>
                        )}
                        {values.masterOperates === 'SI' && (
                          <>
                            <p className="processor-title">MASTER</p>
                            <CustomTable tableData={tableDataMaster} headings={standarComisionHeadings} />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {values.masterOperates === 'SI' && (
                          <>
                            <p className="processor-title">MASTER</p>
                            <PersonalaizedTariffTable
                              tableData={
                                localState.tariff
                                  ? preSettedMastercardTariff
                                  : conditionsAndTariffPersonalaizedMastercardData
                              }
                              headings={masterPersonalizedComisionHeadings}
                              field="master"
                              setFieldValue={setFieldValue}
                              handleChangePersonalaizedTable={handleChangePersonalaizedTable}
                              handleBlurPersonalaizedTable={handleBlurPersonalaizedTable}
                            />
                          </>
                        )}
                        {values.visaOperates === 'SI' && (
                          <>
                            <p className="processor-title">VISA</p>
                            <PersonalaizedTariffTable
                              tableData={
                                localState.tariff
                                  ? preSettedVisaTariff
                                  : conditionsAndTariffPersonalaizedVisaData
                              }
                              headings={visaPersonalizedComisionHeadings}
                              field="visa"
                              setFieldValue={setFieldValue}
                              handleBlurPersonalaizedTable={handleBlurPersonalaizedTable}
                              handleChangePersonalaizedTable={handleChangePersonalaizedTable}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={isLoading} />
    </>
  );
}

export default CommerceConditions;
