import { ChangeEvent, Fragment, useRef } from 'react';
import EditIcon from '../../assets/Edit.svg';
import { CustomTableChunk } from '../../types/add_commerce.types';

interface CustomTableProps {
  tableData: Array<CustomTableChunk> | CustomTableChunk;
  headings: string[];
  editableSecondRow?: boolean;
  onChangeTableData?: any;
  onBlurTableData?: any;
  editableThirdRow?: boolean;
}

function CustomTable({
  headings,
  tableData,
  onChangeTableData,
  onBlurTableData,
  editableSecondRow = false,
  editableThirdRow = false,
}: CustomTableProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEditFee = (id: string, heading: string) => {
    const getInputById = document.getElementById(`${heading}_${id}_input`);

    if (getInputById) {
      getInputById.focus();
      (getInputById as HTMLInputElement).value = '';
    }
    getInputById && getInputById.focus();
  };

  return (
    <div className="table-wrapper">
      {Array.isArray(tableData) &&
        tableData.map((tableChunk: CustomTableChunk, index: number) => (
          <Fragment key={`${index}_main-div`}>
            <div className={`title-row ${tableChunk.title ? 'd-flex' : 'd-none'}`}>
              <p className="input-subtitle">{tableChunk.title}</p>
            </div>
            <div className={`table-category ${tableChunk.headings ? 'd-flex' : 'd-none'}`}>
              {tableChunk.headings?.map((heading: string, index: number) => (
                <p key={index}>{heading}</p>
              ))}
            </div>
            <div className="table-title">
              {headings.map((heading: string, index: number) => (
                <div key={`${heading}_${index}`}>{heading}</div>
              ))}
            </div>
            {tableChunk.rows.map((row, index: number) => (
              <div className="table-description" key={`${row.plan}_${index}`} id={index.toString()}>
                <div>{row.plan}</div>
                {editableSecondRow ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <input
                      ref={inputRef}
                      type="number"
                      className="edit-input"
                      id={`${headings[0]}_${row?.id?.toString() || index.toString()}_input`}
                      value={Number(row.currency)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTableData(e, 'currency')}
                      onBlur={(e: ChangeEvent<HTMLInputElement>) => onBlurTableData(e, 'currency')}
                    />
                    {row.suffix && <span className="suffix">{row.suffix}</span>}
                    <span
                      onClick={() => handleEditFee(row?.id?.toString() || index.toString(), headings[0])}
                      className="h-100 justify-content-center align-items-center edit-tariff"
                    >
                      <img alt="edit" src={EditIcon} />
                    </span>
                  </div>
                ) : (
                  <div>{typeof row.currency === 'number' ? row.currency.toFixed(2) : row.currency}</div>
                )}
                <div>
                  {editableThirdRow ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <input
                        ref={inputRef}
                        className="edit-input"
                        id={`${headings[0]}_${row?.id?.toString() || index.toString()}_input`}
                        value={row.fees}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeTableData(event, 'fees')}
                      />
                      <span
                        onClick={() => handleEditFee(row?.id?.toString() || index.toString(), headings[0])}
                        className="h-100 justify-content-center align-items-center edit-tariff"
                      >
                        <img alt="edit" src={EditIcon} />
                      </span>
                    </div>
                  ) : (
                    <div>
                      {typeof row.fees === 'number'
                        ? row.fees.toFixed(2).toString().replace('.', ',')
                        : row.fees.toString().replace('.', ',')}
                      {row.suffix && <span className="suffix">{row.suffix}</span>}
                    </div>
                  )}
                </div>
                <div>
                  {typeof row.deferred === 'number'
                    ? row.deferred.toFixed(2).toString().replace('.', ',')
                    : row.deferred.toString().replace('.', ',')}
                  {row.suffix && <span className="suffix">{row.suffix}</span>}
                </div>
              </div>
            ))}
            {tableData.length !== index + 1 && <hr className="table-separator" />}
          </Fragment>
        ))}
    </div>
  );
}

export default CustomTable;
