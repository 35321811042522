import { useState, ChangeEvent } from 'react';
import { Form, Formik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import FileInput from '../../../components/Input/FileInput';
import schemas from '../../../utils/schemas';
import { NewBeneficiaryValues } from '../../../types/add_commerce.types';

interface NewBeneficiaryIdProps {
  innerRef: any;
  beneficiary: NewBeneficiaryValues;
  index: number;
  handleSubmit: (idFrontFile: File | string | null, idBackFile: File | string | null, index: number) => void;
}

function NewBeneficiaryId({ innerRef, beneficiary, handleSubmit, index }: NewBeneficiaryIdProps) {
  const [idFrontFile, setIdFrontFile] = useState<File | string | null>(beneficiary.frontDocument || null);
  const [idBackFile, setIdBackFile] = useState<File | string | null>(beneficiary.backDocument || null);

  const handleFile = (
    e: ChangeEvent<HTMLInputElement>,
    name: string,
    setFieldValue: (name: string, value: number) => void,
  ) => {
    if (e?.target?.files) {
      setFieldValue(name, e.target.files.length);
      name === 'frontDocument' && setIdFrontFile(e.target.files[0]);
      name === 'backDocument' && setIdBackFile(e.target.files[0]);
    }
  };

  return (
    <Formik
      innerRef={innerRef}
      initialValues={{
        frontDocument: beneficiary?.frontDocument ? 1 : null,
        backDocument: beneficiary?.backDocument ? 1 : null,
      }}
      validationSchema={schemas.ActivateCommerceSixthStepIds}
      onSubmit={() => handleSubmit(idFrontFile, idBackFile, index)}
      validateOnMount
    >
      <Form>
        <Row className="form-row">
          <p className="input-title input-subtitle">BENEFICIARIO {beneficiary.firstName.toUpperCase()}:</p>
          <Col md={6}>
            <label className="input-title" htmlFor={`beneficiaryIdFront_${index}`}>
              Frente de Documento de identidad: <span className="required-asterisk">*</span>
            </label>
            <FileInput name="frontDocument" setFile={handleFile} file={idFrontFile} index={index} />
          </Col>
          <Col md={6} className="id-back-wrapper">
            <label className="input-title" htmlFor={`beneficiaryIdBack_${index}`}>
              Dorso de Documento de identidad: <span className="required-asterisk">*</span>
            </label>
            <FileInput name="backDocument" setFile={handleFile} file={idBackFile} index={index} />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
}

export default NewBeneficiaryId;
