import { UpdateFilesRequest } from '../types/business/request';

const getFileExtension = (fileType: string): string => fileType.split('/')?.[1];

const parseExtraDocuments = (documentName: string, updateFilesRequest: any, formData: FormData) => {
  if (updateFilesRequest[documentName]) {
    if (typeof updateFilesRequest[documentName] === 'string') {
      formData.append(documentName, updateFilesRequest[documentName]);
    } else {
      updateFilesRequest[documentName] &&
        formData.append(
          documentName,
          updateFilesRequest[documentName],
          `${documentName}.${getFileExtension(updateFilesRequest[documentName].type)}`,
        );
    }
  }
};

export const getParsedFormData = (updateFilesRequest: UpdateFilesRequest): FormData => {
  const formData = new FormData();
  const extraDocuments: string[] = ['extraDocumentOne', 'extraDocumentTwo', 'extraDocumentThree'];

  // endpoint expects file data to be: either a URL string of the already uploaded file in AWS's s3
  if (typeof updateFilesRequest.rut === 'string') {
    formData.append('business', updateFilesRequest.rut);
    // or a File to upload, with a structured filename specified in third argument of FormData's append method
  } else {
    formData.append(
      'business',
      updateFilesRequest.rut,
      `business.${getFileExtension(updateFilesRequest.rut.type)}`,
    );
  }

  updateFilesRequest.beneficiaryIds.forEach((beneficiaryData) => {
    if (typeof beneficiaryData.idFront === 'string') {
      formData.append('beneficiary', beneficiaryData.idFront);
    } else {
      formData.append(
        'beneficiary',
        beneficiaryData.idFront,
        `${beneficiaryData.beneficiaryId}_frontCI.${getFileExtension(beneficiaryData.idFront.type)}`,
      );
    }
    if (typeof beneficiaryData.idBack === 'string') {
      formData.append('beneficiary', beneficiaryData.idBack);
    } else {
      formData.append(
        'beneficiary',
        beneficiaryData.idBack,
        `${beneficiaryData.beneficiaryId}_backCI.${getFileExtension(beneficiaryData.idBack.type)}`,
      );
    }
  });

  extraDocuments.forEach((document: string) => {
    parseExtraDocuments(document, updateFilesRequest, formData);
  });

  return formData;
};

export const contractFormData = (file: File) => {
  const formData = new FormData();
  formData.append('contract', file, file.name);

  return formData;
};
