import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import ResendIcon from '../../assets/ResendArrow.svg';
import DownloadContractIcon from '../../assets/file-upload-solid.svg';
import ConfirmUploadContractModal from '../../screens/AddCommerce/Contracts/ConfirmUploadContractModal';
import {
  downloadSignatureRequest,
  getBusinessContract,
  resendVaFirmaContract,
  uploadContract,
} from '../../services/commerce.service';
import { triggerToasts } from '../../utils/helpers';
import BorderlessButton from '../Buttons/BorderlessButton';

interface ShareBlockProps {
  email: string;
  commerceId: number | string;
  rut: string | null;
  handleStatus?: () => void;
}

function ShareBlock({ email, commerceId, rut, handleStatus }: ShareBlockProps) {
  const [resendIsLoading, setResendIsLoading] = useState<boolean>(false);
  const [uploadIsLoading, setUploadIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleModal = () => setShowModal((prevState: boolean) => !prevState);

  const uploadFile = async (e: any) => {
    setUploadIsLoading(true);
    const response = await uploadContract(e.target.files[0], +commerceId);
    setUploadIsLoading(false);
    triggerToasts(
      response.result ? { status: 200, message: 'El contrato se ha subido correctamente' } : response,
    );
    handleStatus!();
  };

  const handleUploadFile = () => {
    toggleModal();
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput?.click();
    fileInput?.addEventListener('change', uploadFile);
  };

  const handleDownloadFile = async () => {
    const businessContracts: any = await getBusinessContract(+commerceId);

    for (const businessContract of businessContracts.data) {
      const contract = await downloadSignatureRequest(businessContract.vaFirmaUuid);
      const base64Response = await fetch(`data:application/pdf;base64,${contract}`);
      const blob = await base64Response.blob();
      const href = URL.createObjectURL(blob);
      const a = Object.assign(document.createElement('a'), {
        href,
        download: `${rut}_${businessContract.contractType}`,
      });

      a.click();
    }
  };

  const handleResendEmail = async () => {
    setResendIsLoading(true);
    const response = await resendVaFirmaContract(+commerceId, email);
    if (response?.data) {
      setResendIsLoading(false);
      triggerToasts({ status: 200, message: 'Contrato enviado correctamente' });
    } else {
      setResendIsLoading(false);
      triggerToasts('Ocurrió un error al enviar el contrato');
    }
  };

  return (
    <>
      <ConfirmUploadContractModal
        show={showModal}
        toggleModal={toggleModal}
        handleUploadFile={handleUploadFile}
      />
      <div className="h-100">
        <Row className="text-center">
          <div className="confirmation-card border-none w-100 mt-4">
            <div className="confirmation-info" key={`email`}>
              <div className="info-fieldname">Email</div>
              <div className="info semibold">{email}</div>
            </div>
          </div>
          <Container className="d-flex justify-content-around mt-4 buttons-container">
            <BorderlessButton
              buttonClass="resend-border"
              text="Reenviar"
              icon={ResendIcon}
              onClick={handleResendEmail}
              loading={resendIsLoading}
            />
            <BorderlessButton text="Descargar" icon={DownloadContractIcon} onClick={handleDownloadFile} />
            <BorderlessButton
              text="Cargar contrato a mano"
              icon={DownloadContractIcon}
              onClick={toggleModal}
              loading={uploadIsLoading}
            />
          </Container>
        </Row>
      </div>
    </>
  );
}

export default ShareBlock;
